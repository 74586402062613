import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { FormContext } from "../../../../Context/PaymentsContext";

const Amount = () => {
  const { formik } = useContext(FormContext);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          Type
        </Typography>
        <FormControl
          variant="standard"
          className="input-style outlined-input select-style textfield-rouded-md"
          sx={{
            width: "100%",
            borderRadius: "18px",
            mb: 1,
            pl: 4,
            mr: 4,
          }}
        >
          <Select
            sx={{
              "&.MuiInput-root:before": {
                borderBottom: "none !important",
              },
            }}
            input={<Input />}
            value={formik.values.amount_type}
            onChange={(event) => {
              formik.setFieldValue("amount_type", event.target.value);
              formik.setFieldValue("amount", "");
            }}
          >
            <MenuItem value="percentage">Percentage discount</MenuItem>
            <MenuItem value="fixed_amount">Fixed amount discount</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          variant="h6"
          component="h2"
          className="sub-text tertiary-text label-bottom-space"
        >
          {formik.values.amount_type == "percentage"
            ? "Percentage off"
            : "Discount amount"}
        </Typography>
        <FormControl
          sx={{ width: "100%" }}
          className="input-style small-radius"
        >
          <TextField
            placeholder={
              formik.values.amount_type == "percentage"
                ? " Percentage off"
                : "Discount amount"
            }
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="outline-input textfield-rouded-md input-text-style small-radius"
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  {formik.values.amount_type == "percentage" ? "%" : "$"}
                </InputAdornment>
              ),
              sx: {
                "input::placeholder": {
                  color: "#757575",
                },
              },
            }}
            sx={{
              borderRadius: "18px !important",
              ".MuiInputBase-input": {
                paddingLeft: "30px",
              },

              ".MuiInputBase-root": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
                "&.Mui-focused": {
                  " .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#eee",
                },
              },
            }}
          />
        </FormControl>
        {formik.errors.amount && formik.touched.amount && (
          <FormHelperText className="field-helper-text error">
            {formik.errors.amount}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
};

export default Amount;
