import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DeleteIcon from "@mui/icons-material/Delete";
import { CouponService } from "../../../Services/CouponService";
import MakeToast from "../../../Helpers/MakeToast";
import { useContext, useState } from "react";
import { CouponContext } from "../../../Context/CouponContext";
import ConfirmationModal from "../../Common/ConfirmationModal";

const CouponsList = ({ coupons }) => {
  const { setCoupons } = useContext(CouponContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [id, setId] = useState(null);
  const handleDelete = () => {
    setConfirmationOpen(false);
    CouponService.remove({ id: id })
      .then((response) => {
        MakeToast("success", response);
        setCoupons(response.data.data);
      })
      .catch((error) => MakeToast("error", error));
  };
  return (
    <>
      <Grid
        sx={{ pb: 2, borderBottom: "1px solid rgb(221, 221, 221) !important" }}
        container
      >
        <Grid xs={10} item>
          <Typography sx={{ fontSize: 18, fontWeight: "light" }}>
            Your coupons
          </Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography
            sx={{ fontSize: 18, fontWeight: "light", float: "right" }}
          >
            {coupons.length}
          </Typography>
        </Grid>
      </Grid>
      {coupons.map((coupon) => (
        <Grid
          sx={{
            borderBottom: "1px solid rgb(221, 221, 221) !important",
            py: 2,
          }}
          key={coupon.id}
          container
        >
          <Grid sx={{ display: "flex" }} xs={10} item>
            <LoyaltyIcon sx={{ fontSize: 40 }} />
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontSize: 18, fontWeight: "light" }}>
                {coupon.name}
              </Typography>
              <Chip sx={{ fontSize: 12, height: "25px" }} label={coupon.id} />
            </Box>
          </Grid>
          <Grid xs={2} sx={{ textAlign: "end", alignSelf: "center" }} item>
            <IconButton
              onClick={() => {
                setId(coupon.id);
                setConfirmationOpen(true);
              }}
              aria-label="delete"
            >
              <DeleteIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <ConfirmationModal
        title="Are you sure?"
        description="Your coupon will be remove from Beachify."
        setOpen={setConfirmationOpen}
        open={confirmationOpen}
        confirmAction={handleDelete}
      />
    </>
  );
};

export default CouponsList;
