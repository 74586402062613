import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import PaymentMethodsModal from "../PaymentMethod/Modal";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PaymentMethodService } from "../../../Services/PaymentMethodService";
import MakeToast from "../../../Helpers/MakeToast";
import { AddPaymentContext } from "../../../Context/PaymentsContext";
import PaymentMethodsList from "../PaymentMethodsList";
import CouponsSection from "../Coupons/CouponsSection";
const Payments = () => {
  const [showModal, setShowModal] = useState(false);
  const [methods, setMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useHistory();
  const getPaymenthMethods = () => {
    PaymentMethodService.hostPaymentMethods()
      .then((response) => {
        setLoading(false);
        setMethods(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  useEffect(() => {
    getPaymenthMethods();
  }, []);
  return (
    <AddPaymentContext.Provider
      value={{
        methods: methods,
        setMethods: setMethods,
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "600", fontSize: 25 }}>
          Your payments
        </Typography>
        <Typography sx={{ fontWeight: "light", fontSize: 18 }}>
          Keep track of all your payments and refunds.
        </Typography>
        <Button
          size="small"
          type="button"
          sx={{
            backgroundColor: "black !important",
            mt: 2,
            mb: 7,
            borderRadius: 16,
            border: "1px solid black !important",
          }}
          className="btn-primary submit-btn "
          onClick={() => navigate.push("/account-settings/your-payments")}
        >
          Manage payments
        </Button>
        <Typography sx={{ fontWeight: "600", fontSize: 25 }}>
          Payment methods
        </Typography>
        <Typography
          sx={{
            fontWeight: "light",
            fontSize: 18,
            mb: 1,
          }}
        >
          Add a payment method using our secure payment system, then start
          planning your next trip.
        </Typography>
        <Box
          sx={{
            pb: 3,
            borderTop: "1px solid rgb(221, 221, 221) !important",
          }}
        >
          {loading ? (
            <Grid spacing={2} sx={{ mt: 2 }} container>
              <Grid item>
                <Skeleton width={60} height={70} />
              </Grid>
              <Grid item>
                <Skeleton width={100} height={30} />
                <Skeleton width={200} height={30} />
              </Grid>
            </Grid>
          ) : (
            <PaymentMethodsList list={methods} />
          )}
        </Box>
        <Button
          size="small"
          type="button"
          sx={{
            backgroundColor: "black !important",
            mt: 2,
            mb: 7,
            borderRadius: 16,
            border: "1px solid black !important",
          }}
          className="btn-primary submit-btn "
          onClick={() => setShowModal(true)}
        >
          Add payments method
        </Button>
        <CouponsSection />

        <PaymentMethodsModal
          open={showModal}
          onClose={() => setShowModal(false)}
        />
      </Box>
    </AddPaymentContext.Provider>
  );
};

export default Payments;
