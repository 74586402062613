import {
  Box,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Typography,
} from "@mui/material";
import images from "../../assets/js/images";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { PaymentMethodService } from "../../Services/PaymentMethodService";
import MakeToast from "../../Helpers/MakeToast";
import { AddPaymentContext } from "../../Context/PaymentsContext";
import { useContext } from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import AppHelper from "../../Helpers/AppHelper";

const PaymentMethodsList = ({ list }) => {
  const { setMethods } = useContext(AddPaymentContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleRemove = (id) => {
    setAnchorEl(null);
    PaymentMethodService.hostRemovePaymentMethod(id)
      .then((response) => {
        setMethods(response.data.data);
        MakeToast("success", response);
      })
      .catch((error) => MakeToast("error", error));
  };
  const handleDefault = (id) => {
    setAnchorEl(null);
    PaymentMethodService.hostDefaultPaymentMethod(id)
      .then((response) => {
        setMethods(response.data.data);
        MakeToast("success", response);
      })
      .catch((error) => MakeToast("error", error));
  };
  const cardTypesData = AppHelper.lists().cardTypes;
  return list.map((item) => (
    <Grid
      sx={{
        borderBottom: "1px solid rgb(221, 221, 221) !important",
        py: 2,
      }}
      key={item.id}
      container
    >
      {cardTypesData[item.card_type] != null ? (
        <Grid sx={{ display: "flex" }} xs={10} item>
          <img src={cardTypesData[item.card_type].icon} />
          <Box sx={{ ml: 2 }}>
            <Typography sx={{ fontSize: 18, fontWeight: "light" }}>
              {cardTypesData[item.card_type].name}
            </Typography>
            {item.default == 1 && (
              <Chip sx={{ fontSize: 12, height: "25px" }} label="Default" />
            )}

            <Typography sx={{ fontSize: 16, fontWeight: "light" }}>
              {item.expiration_date}
            </Typography>
          </Box>
        </Grid>
      ) : (
        <Grid sx={{ display: "flex" }} xs={10} item>
          <PaymentIcon sx={{ fontSize: 40 }} />
          <Box sx={{ ml: 2 }}>
            <Typography sx={{ fontSize: 18, fontWeight: "light" }}>
              Payment Method
            </Typography>
            {item.default == 1 && (
              <Chip sx={{ fontSize: 12, height: "25px" }} label="Default" />
            )}

            <Typography sx={{ fontSize: 16, fontWeight: "light" }}>
              {item.expiration_date}
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid xs={2} sx={{ textAlign: "end", alignSelf: "center" }} item>
        <IconButton onClick={handleClick} aria-label="delete">
          <MoreHorizIcon sx={{ fontSize: 20 }} />
        </IconButton>
        <Popper id={"simple-popper-" + item.id} open={open} anchorEl={anchorEl}>
          <Box
            sx={{
              boxShadow: "0px 0px 20px 0px rgba(9, 6, 15, 0.02)",
              bgcolor: "background.paper",
            }}
          >
            <List sx={{ pb: 0 }}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleDefault(item.id);
                  }}
                  sx={{ py: 0 }}
                >
                  <ListItemIcon>
                    <StarIcon sx={{ fontSize: 18, width: "35px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Set as Default" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleRemove(item.id);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon sx={{ fontSize: 18, width: "35px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Remove" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Popper>
      </Grid>
    </Grid>
  ));
};

export default PaymentMethodsList;
