import React from 'react';
import {
    Input,
    InputAdornment,
    FormControl,
    Button,
    Typography,
    Link,
    Box,
    Checkbox,
    ButtonGroup,
    Select,
    InputBase,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Grid from '@mui/material/Unstable_Grid2';

import { styled, useTheme } from '@mui/material/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Slider from "react-slick";

import images from "../../assets/js/images";

import $ from 'jquery';
import moment from 'moment';
import '../../Helpers/moment-custom-locale';
// import '../../Helpers/daterangepickers';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterModal from '../Modal/FilterModal';
import GoogleAutoCompleteInput from '../Inputs/GoogleAutoCompleteInput';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppHelper from '../../Helpers/AppHelper';
import RangePicker from '../Inputs/RangePicker';
import ActivitiesPopover from './ActivitiesPopover';

const HeroBanner = ({ settings }) => {
    // ================= popper menu
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const anchorRef = React.useRef('');

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }


    const [searchLocationOpen, setLocationOpen] = React.useState(false);
    const anchorRefLocation = React.useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    const prevOpen1 = React.useRef(searchLocationOpen);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;

        if (prevOpen1.current === true && searchLocationOpen === false) {
            anchorRefLocation.current.focus();
        }

        prevOpen1.current = searchLocationOpen;
    }, [open]);

    // ================= customize chcekbox
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: 3,
        width: 24,
        height: 24,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E5E4E4',
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#FF4110',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#FF4110',
        },
    });

    // ======= Quantity button
    const [count, setCount] = React.useState(0);

    const [openFilter, setOpenFilter] = React.useState(false);
    const [address, setAddress] = React.useState(null);
    const [googleAutocompleteData, setGoogleAutocompleteData] = React.useState({})
    const dispatch = useDispatch();
    const locations = useLocation();


    React.useEffect(() => {
        if (googleAutocompleteData.address) {
            setAddress(googleAutocompleteData.address)

            dispatch({
                type: "SET_LOCATION",
                payload: googleAutocompleteData
            });

            if (locations.pathname == "/search-result") {
                navigate.push("/search-result?search=" + AppHelper.searchRandomNumber());
                return;
            }
        }
    }, [googleAutocompleteData]);

    const setRangePicker = (start, end) => {
        setStartDate(start);
        setEndDate(end);

        dispatch({
            type: "SET_DATE",
            payload: {
                start_date: start,
                end_date: end,
            },
        });

        if (locations.pathname == "/search-result") {
            navigate.push("/search-result?search=" + AppHelper.searchRandomNumber());
            return;
        }
    };

    const [anchorActivitiesEl, setAnchorActivitiesEl] = React.useState(null);
    const handleToggleActivities = (e) => {
        setAnchorActivitiesEl(e.currentTarget);
    };
    const handleCloseActivities = (event) => {
        setAnchorActivitiesEl(null);
    };


    return (
        <>
            <RangePicker
                selector="#new-rng-picker-mobile"
                startdate={startDate}
                enddate={endDate}
                setRangePicker={setRangePicker}
            />
            <Grid xs={12}>
                <Slider {...settings} className="hero-banner">
                    <div className="hero-image-item">
                        <img src={images.heroImage} className="hero-image" alt="hero-img" />
                        <div className="slider-caption">
                            <div className="slider-caption-block">
                                <Typography variant="h1">Browse & book beach gear, water sports & more for less, in minutes</Typography>
                                <div className="rental-search-form">
                                    <FormControl
                                        variant="standard"
                                        className="input-style outlined-input location-input"
                                        underline="none"
                                    >
                                        <GoogleAutoCompleteInput
                                            placeholder="Location"
                                            value={address || ''}
                                            onFocus={() => {
                                                if (document.getElementsByClassName('pac-container').length > 0) {
                                                    document.getElementsByClassName('pac-container')[0].classList.value = "home-location " + document.getElementsByClassName('pac-container')[0].classList.value;
                                                }
                                            }}
                                            onBlur={() => {
                                                if (document.getElementsByClassName('pac-container').length > 0) {
                                                    document.getElementsByClassName('pac-container')[0].classList.value = document.getElementsByClassName('pac-container')[0].classList.value.replace('home-location', "");
                                                }
                                            }}
                                            onChange={(e) => { setAddress(e.target.value) }}
                                            setGoogleAutocompleteData={setGoogleAutocompleteData}
                                            className="searchLocationBox"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="input-image"
                                                        underline="none"
                                                        sx={{ pl: 1.5, pr: 1 }}
                                                    >
                                                        <img src={images.locationIcon} alt="location" />
                                                    </InputAdornment>
                                                )
                                            }}
                                            variant="standard"
                                        />
                                    </FormControl>
                                    <FormControl
                                        variant="standard"
                                        className="input-style outlined-input"
                                        underline="none"
                                    >
                                        <Input
                                            id="new-rng-picker-mobile"
                                            placeholder="Dates"
                                            underline="none"
                                            disableUnderline={true}
                                            className="range-picker"
                                            value={startDate || ''}
                                            readOnly
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    className="input-image"
                                                    underline="none"
                                                    sx={{ pl: 1.5, pr: 1 }}
                                                >
                                                    <img src={images.calendarIcon} alt="calendar" />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl
                                        variant="standard"
                                        className="input-style outlined-input"
                                        underline="none"
                                    >
                                        <Input
                                            id="input-with-icon-adornment search-bear-button"
                                            placeholder="Beach Gear & Activities"
                                            readOnly={true}
                                            underline="none"
                                            disableUnderline={true}
                                            aria-controls={open ? "search-bear-menu" : undefined}
                                            aria-expanded={open ? "true" : undefined}
                                            aria-haspopup="true"
                                            value={''}
                                            onClick={handleToggleActivities}
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    className="input-image"
                                                    underline="none"
                                                    sx={{ pl: 1.5, pr: 1 }}
                                                >
                                                    <img src={images.searchIcon} alt="search" />
                                                </InputAdornment>
                                            }
                                        />
                                        <ActivitiesPopover
                                            anchorActivitiesEl={anchorActivitiesEl}
                                            handleCloseActivities={handleCloseActivities}
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        // fullHeight
                                        disableElevation
                                        variant="contained"
                                        href="#"
                                        onClick={() => {
                                            setOpenFilter(true);
                                        }}
                                        className="rounded-button filter-button"
                                    >
                                        <img src={images.filterIcon} alt="filter" />
                                        <span>Filter</span>
                                    </Button>
                                    {openFilter && <>
                                        <FilterModal isOpen={openFilter} setLoginOpen={setOpenFilter} sliderMaxValue={1000} />
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </Grid>
        </>
    )
}

export default HeroBanner;
