import React, { useRef, useState } from "react";
import { useHistory, Link as RouterLink, useLocation } from "react-router-dom";
import {
  Stack,
  Container,
  Link,
  Avatar,
  Badge,
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Box,
  Typography,
  Button,
  ButtonGroup,
  List,
  ListItemIcon,
  Divider,
  Input,
  InputAdornment,
  FormControl,
} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Unstable_Grid2";

import images from "../../assets/js/images";
import Scrollbars from "react-custom-scrollbars";

import { GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import MakeToast from "../../Helpers/MakeToast";
import AuthModal from "../Common/AuthModal";

import { initFacebookSdk } from "../../assets/js/initFbSdk";
import FilterModal from "../Modal/FilterModal";
import Support from "../Support/Support";

import AppHelper from "../../Helpers/AppHelper";
import LocationPopover from "../Common/LocationPopover";
import ActivitiesPopover from "../Common/ActivitiesPopover";

import RangePicker from "../Inputs/RangePicker";
import AuthHelper from "../../Helpers/AuthHelper";
import { LoginAndSecurityService } from "../../Services/LoginAndSecurityService";
import QuantityInput from "../../Components/Inputs/QuantityInput";
import { CartService } from "../../Services/CartService";
import GoogleAutoCompleteInput from "../../Components/Inputs/GoogleAutoCompleteInput";
import Notifications from "./Notifications";

const Header = () => {
  const history = useHistory();
  const [active, setActive] = React.useState("/");
  const currency = AppHelper.currency();

  const authModalRef = useRef();
  const dispatch = useDispatch();
  const [openSupport, setOpenSupport] = React.useState(false);

  const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

  const [cartOpen, setCartOpen] = useState(false);
  const cartRef = useRef(null);

  const [notifOpen, setNotifOpen] = useState(false);
  const notifRef = useRef(null);

  const [profileOpen, setProfileOpen] = useState(false);
  const profileRef = useRef(null);

  const handleToggleCart = () => {
    setCartOpen((prevOpen) => !prevOpen);
  };

  const handleToggleNotification = () => {
    setNotifOpen((prevOpen) => !prevOpen);
  };

  const handleToggleProfile = () => {
    setProfileOpen((prevOpen) => !prevOpen);
  };

  const handleCloseCart = (event) => {
    if (cartRef.current && cartRef.current.contains(event.target)) {
      return;
    }
    setCartOpen(false);
  };

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  React.useEffect(() => {
    setActive(location.pathname);

    initFacebookSdk();
    setTimeout(() => {
      try {
        gapi.load("client:auth2", () => {
          gapi.client.init({
            clientId: clientId,
            scope: "",
          });
        });
      } catch (error) { }
    }, 1000);
  });

  const handleLogout = async () => {
    try {
      await LoginAndSecurityService.removeDeviceHistory(AuthHelper.getToken());
    } catch (err) { }

    dispatch({
      type: "LOGOUT",
      payload: [],
    });
    
    dispatch({
      type: "SET_LOCATION",
      payload: {}
    });

    setRangePicker()
    setAddress("")
    
    navigate.push('/?search=' + AppHelper.searchRandomNumber());
    MakeToast("success", "Logged out successfully");
  };

  const handleCloseNotification = (event) => {
    if (notifRef.current && notifRef.current.contains(event.target)) {
      return;
    }
    setNotifOpen(false);
  };

  const handleOpenAuthModal = (event) => {
    handleCloseProfile(event);
    authModalRef.current.openLogin(true);
  };

  const handleCloseProfile = (event) => {
    if (profileRef.current && profileRef.current.contains(event.target)) {
      return;
    }
    setProfileOpen(false);
  };

  const handleSignUpModal = (event) => {
    handleCloseProfile(event);
    authModalRef.current.openSignUp(true);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setCartOpen(false);
      setNotifOpen(false);
      setProfileOpen(false);
    } else if (event.key === "Escape") {
      setCartOpen(false);
      setNotifOpen(false);
      setProfileOpen(false);
    }
  }

  const [open, setOpen] = React.useState(false);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const [cartlist, setCartList] = React.useState([]);
  const getCartList = () => {
    if (isLoggedIn && user.role_id == "3") {
      AppHelper.cartLists(setCartList);
    } else {
      setCartList([]);
    }
  };

  React.useEffect(() => {
    getCartList();
  }, []);

  const updates = useSelector((state) => state.cart);
  React.useEffect(() => {
    getCartList();
  }, [user, updates, isLoggedIn]);

  const [openFilter, setOpenFilter] = useState(false);

  const [anchorActivitiesEl, setAnchorActivitiesEl] = React.useState('');
  const handleToggleActivities = (e) => {
    setAnchorActivitiesEl(e.currentTarget);
  };
  const handleCloseActivities = (event) => {
    setAnchorActivitiesEl(null);
  };

  const [address, setAddress] = React.useState('');
  const [startEndDate, setStartEndDate] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const locations = useLocation();
  const navigate = useHistory();
  const searchParams = new URLSearchParams(locations.search);
  const searchparam = searchParams.get("search");

  const calculationUpdate = useSelector((state) => state.calculationReducers);
  React.useEffect(() => {
    window.history.replaceState(null, null, window.location.pathname);

    var searchStorage = sessionStorage.getItem("searchItems") != null ? JSON.parse(sessionStorage.getItem("searchItems")).data : {};
   
    if (searchStorage.location && searchStorage.location.address) {
      setAddress(searchStorage.location.address);
    } 

    if (searchStorage.start_date != "" && searchStorage.end_date != "") {
      var storageStartDate = searchStorage.start_date;
      var storageEndDate = searchStorage.end_date;

      setStartDate(storageStartDate);
      setEndDate(storageEndDate);

      if(storageStartDate==storageEndDate){
        setStartEndDate(storageStartDate.replaceAll('-', '/'));
      }else{
        var startYear = storageStartDate.substr(-4)
        var endYear = storageEndDate.substr(-4)

        if(startYear==endYear){
          setStartEndDate(storageStartDate.replaceAll('-', '/').replace('/'+startYear, '')+' - '+storageEndDate.replaceAll('-', '/'));
        }else{
          setStartEndDate(storageStartDate.replaceAll('-', '/')+' - '+storageEndDate.replaceAll('-', '/'));
        }
      }
    } 
  }, [searchparam, calculationUpdate]);

  const setRangePicker = (start="", end="") => {
    setStartDate(start);
    setEndDate(end);

    if(start==end){
      setStartEndDate(start.replaceAll('-', '/'));
    }else{
      var startYear = start.substr(-4)
      var endYear = end.substr(-4)

      if(startYear==endYear){
        setStartEndDate(start.replaceAll('-', '/').replace('/'+startYear, '')+' - '+end.replaceAll('-', '/'));
      }else{
        setStartEndDate(start.replaceAll('-', '/')+' - '+end.replaceAll('-', '/'));
      }
    }

    dispatch({
      type: "SET_DATE",
      payload: {
        start_date: start,
        end_date: end,
      },
    });

    dispatch({
      type: "CALCULATION_UPDATE",
      payload: true
    });

    if (locations.pathname == "/search-result") {
      navigate.push("/search-result?search=" + AppHelper.searchRandomNumber());
      return;
    }
  };

  

  const [googleAutocompleteData, setGoogleAutocompleteData] = React.useState({})
  React.useEffect(() => {
    if (googleAutocompleteData.address) {
      setAddress(googleAutocompleteData.address)

      dispatch({
        type: "SET_LOCATION",
        payload: googleAutocompleteData
      });

      if (locations.pathname == "/search-result") {
        navigate.push("/search-result?search=" + AppHelper.searchRandomNumber());
        return;
      }
    }
  }, [googleAutocompleteData]);

  const [notifyDot, setNotifyDot] = useState(0);
  React.useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        AppHelper.notificationRead(setNotifyDot)
      }, 1000);
    }
  }, [locations.pathname]);

  return (
    <>
      <RangePicker
        selector="#new-rng-picker"
        startdate={startDate}
        enddate={endDate}
        setRangePicker={setRangePicker}
        mindate={new Date()}
      />
      <Support setOpen={setOpenSupport} open={openSupport} />

      <header className="header-section bottom-border">
        <Container maxWidth="lg" sx={{ py: 3 }}>
          <Grid container>
            <Grid xs={6} sm={6} md={2} lg={2} sx={{ margin: "auto 0" }}>
              <RouterLink to="/">
                {/* <Link underline="none" className="logo"> */}
                  <img src={images.logo} alt="Beachify-logo" />
                {/* </Link> */}
              </RouterLink>
            </Grid>
            <Grid md={8} lg={8}>
              {!isLoggedIn || user.role_id == "3" ?
                <div className="rental-search-form">
                  <FormControl
                    variant="standard"
                    className="input-style outlined-input location-input"
                    underline="none"
                  >
                    <GoogleAutoCompleteInput
                      placeholder="Location"
                      value={address}
                      onFocus={() => {
                        if (document.getElementsByClassName('pac-container').length > 0) {
                          document.getElementsByClassName('pac-container')[0].classList.value = "home-location " + document.getElementsByClassName('pac-container')[0].classList.value;
                        }
                      }}
                      onBlur={() => {
                        if (document.getElementsByClassName('pac-container').length > 0) {
                          document.getElementsByClassName('pac-container')[0].classList.value = document.getElementsByClassName('pac-container')[0].classList.value.replace('home-location', "");
                        }
                      }}
                      onChange={(e) => { setAddress(e.target.value) }}
                      setGoogleAutocompleteData={setGoogleAutocompleteData}
                      className="searchLocationBox"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="input-image"
                            underline="none"
                            sx={{ pl: 1.5, pr: 1 }}
                          >
                            <img src={images.locationIcon} alt="location" />
                          </InputAdornment>
                        )
                      }}
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    className="input-style outlined-input"
                    underline="none"
                  >
                    <Input
                      id="new-rng-picker"
                      placeholder="Dates"
                      underline="none"
                      readOnly
                      disableUnderline={true}
                      className="range-picker"
                      value={startEndDate || ''}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          className="input-image"
                          underline="none"
                          sx={{ pl: 1.5, pr: 1 }}
                        >
                          <img src={images.calendarIcon} alt="calendar" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    className="input-style outlined-input beach-gear-search"
                    underline="none"
                  >
                    <Input
                      id="input-with-icon-adornment search-bear-button"
                      placeholder="Beach Gear & Activities"
                      readOnly={true}
                      underline="none"
                      disableUnderline={true}
                      aria-controls={open ? "search-bear-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      value={''}
                      onClick={handleToggleActivities}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          className="input-image"
                          underline="none"
                          sx={{ pl: 1.5, pr: 1 }}
                        >
                          <img src={images.searchIcon} alt="search" />
                        </InputAdornment>
                      }
                    />
                    <ActivitiesPopover
                      anchorActivitiesEl={anchorActivitiesEl}
                      handleCloseActivities={handleCloseActivities}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    className="rounded-button filter-button"
                    onClick={() => {
                      setOpenFilter(true);
                    }}
                  >
                    <img src={images.filterIcon} alt="filter" />
                    <span>Filter</span>
                  </Button>
                  {openFilter && (
                    <>
                      <FilterModal
                        isOpen={openFilter}
                        setLoginOpen={setOpenFilter}
                      />
                    </>
                  )}
                </div>
                : <></>}
            </Grid>
            <Grid xs={6} sm={6} md={2} lg={2}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                className="header-menu"
              >
                <IconButton
                  className="header-icons notification-icon"
                  disableRipple
                  ref={notifRef}
                  id="composition-button"
                  aria-controls={notifOpen ? "composition-menu" : undefined}
                  aria-expanded={notifOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleNotification}
                >
                  <Badge variant="dot" className={notifyDot ? "notification-dot" : ""}>
                    <img src={images.notificationIcon} alt="notification" />
                  </Badge>
                </IconButton>
                {
                notifOpen
                ?
                <Notifications
                  notifOpen={notifOpen}
                  setNotifOpen={setNotifOpen}
                  notifRef={notifRef}
                  handleCloseNotification={handleCloseNotification}
                  handleListKeyDown={handleListKeyDown}
                  setNotifyDot={setNotifyDot}
                />
                :
                <></>
                }
                {!isLoggedIn || user.role_id == "3" ?
                  <>
                    {/* Cart button */}
                    < IconButton
                      className={`header-icons cart-icon ${cartOpen ? "active" : ""
                        }`}
                      disableRipple
                      ref={cartRef}
                      id="composition-button"
                      aria-controls={cartOpen ? "composition-menu" : undefined}
                      aria-expanded={cartOpen ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleCart}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={cartlist.items?.length}
                        className="notification-badge"
                      >
                        <img src={images.cartIcon} alt="cart" />
                      </Badge>
                    </IconButton>
                    <Popper
                      open={cartOpen}
                      anchorEl={cartRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition
                      disablePortal={false}
                      className="menu-popper"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper className="menu-dropdown">
                            <ClickAwayListener onClickAway={handleCloseCart}>
                              <MenuList
                                disablePadding
                                autoFocusItem={cartOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                                className="cart-menu"
                              >
                                <Typography component="h6" className="menu-heading">
                                  Cart
                                </Typography>
                                {cartlist.items?.length ? (
                                  <>
                                    <Scrollbars className="cart-scrollbar" autoHeight autoHeightMin={20} autoHeightMax={285}>
                                      {cartlist.items?.map((v, i) => {
                                        return (
                                          <MenuItem
                                            sx={{
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                            disableRipple
                                          >
                                            <Box className="cart-menu-list">
                                              <ListItemAvatar
                                                sx={{ lineHeight: 0 }}
                                              >
                                                <img
                                                  src={v.featureImg?.imgPath}
                                                  className="border-radius-12 product-image"
                                                  alt=""
                                                />
                                              </ListItemAvatar>
                                              <Box className="cart-info-block">
                                                <RouterLink
                                                  onClick={(e) => {
                                                    handleCloseCart(e);
                                                    navigate.push("/service-detail/" + v.product_slug);
                                                  }}
                                                  className="cart-title text-truncate"
                                                >
                                                  {v.title}
                                                </RouterLink>
                                                <Button
                                                  variant="text"
                                                  disableRipple
                                                  onClick={() => {
                                                    AppHelper.cartDeleteAction(
                                                      v.cart_id,
                                                      getCartList,
                                                      dispatch
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src={images.deleteIcon}
                                                    alt=""
                                                  />
                                                  Delete
                                                </Button>
                                              </Box>
                                            </Box>
                                            <Box className="quantity-block">
                                              <QuantityInput
                                                decrement={() => {
                                                  AppHelper.cartQuantityAction(
                                                    0,
                                                    i,
                                                    v.cart_id,
                                                    v.quantity,
                                                    cartlist,
                                                    getCartList,
                                                    dispatch,
                                                    MakeToast
                                                  );
                                                }}
                                                quantity={v.quantity}
                                                increment={() => {
                                                  AppHelper.cartQuantityAction(
                                                    1,
                                                    i,
                                                    v.cart_id,
                                                    v.quantity,
                                                    cartlist,
                                                    getCartList,
                                                    dispatch,
                                                    MakeToast
                                                  );
                                                }}
                                              />
                                              <Typography component="span">
                                                {currency}
                                                {v.total}
                                              </Typography>
                                            </Box>
                                          </MenuItem>
                                        );
                                      })}
                                    </Scrollbars>
                                    <MenuItem
                                      sx={{
                                        borderBottom: "0",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                      disableRipple
                                    >
                                      <Box className="cart-summary">
                                        <List component="div" disablePadding>
                                          {cartlist.subtotal != "" &&
                                            cartlist.subtotal != "0" ? (
                                            <ListItemText className="summary-price">
                                              Subtotal{" "}
                                              <span>
                                                {currency}
                                                {cartlist.subtotal}
                                              </span>
                                            </ListItemText>
                                          ) : (
                                            <></>
                                          )}
                                          {cartlist.securitydeposittotal != "" &&
                                            cartlist.securitydeposittotal != "0" ? (
                                            <ListItemText className="summary-price">
                                              Security Deposit{" "}
                                              <span>
                                                {currency}
                                                {cartlist.securitydeposittotal}
                                              </span>
                                            </ListItemText>
                                          ) : (
                                            <></>
                                          )}
                                          {cartlist.service_charge_total != "" &&
                                            cartlist.service_charge_total != "0" ? (
                                            <ListItemText className="summary-price">
                                              Beachify Service Fee{" "}
                                              <span>
                                                {currency}
                                                {cartlist.service_charge_total}
                                              </span>
                                            </ListItemText>
                                          ) : (
                                            <></>
                                          )}
                                          {cartlist.total != "" &&
                                            cartlist.total != "0" ? (
                                            <ListItemText className="summary-price summary-price-total">
                                              Total{" "}
                                              <span>
                                                {currency}
                                                {cartlist.total}
                                              </span>
                                            </ListItemText>
                                          ) : (
                                            <></>
                                          )}
                                        </List>
                                      </Box>
                                    </MenuItem>
                                    <MenuItem
                                      className="checkout-btn"
                                      sx={{
                                        borderBottom: "0",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                      disableRipple
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          navigate.push("/cart");
                                        }}
                                        className="rounded-button btn-primary menu-btn"
                                        sx={{ width: "100%" }}
                                      >
                                        Go to checkout
                                      </Button>
                                    </MenuItem>
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      padding: "16px 20px 5px 20px",
                                      display: "inline-block",
                                    }}
                                  >
                                    No item in the cart
                                  </p>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                  : <></>}

                {/* Profile button */}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  className="profile-settings"
                >
                  <IconButton
                    className={`header-icons avatar-icon ${profileOpen ? "active" : ""
                      }`}
                    disableRipple
                    ref={profileRef}
                    id="composition-button"
                    aria-controls={profileOpen ? "composition-menu" : undefined}
                    aria-expanded={profileOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleProfile}
                  >
                    <img src={images.toggleIcon} alt="toggle" />
                    {user?.image != "" && user?.image ? (
                      <Avatar className="profile-avatar profile-image">
                        <img src={user?.image} alt="profile" />
                      </Avatar>
                    ) : (
                      <Avatar className="profile-avatar">
                        <img src={images.profileIcon} alt="profile" />
                      </Avatar>
                    )}
                  </IconButton>
                  <Popper
                    open={profileOpen}
                    anchorEl={profileRef.current}
                    role={undefined}
                    placement="bottom-end"
                    transition
                    disablePortal={false}
                    className="menu-popper profile-popper"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper className="menu-dropdown">
                          <ClickAwayListener onClickAway={handleCloseProfile}>
                            <MenuList
                              className="profile-box"
                              autoFocusItem={profileOpen}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              {isLoggedIn && (
                                <RouterLink to={user.role_id == 2 ? `/profile` : `/customer-profile`}>
                                  <MenuItem
                                    className="profile-name"
                                    onClick={() => { navigate.push(user.role_id == 2 ? `/profile` : `/customer-profile`) }}
                                  >
                                    <ListItemIcon className="profile-img-bg">
                                      {user?.image != "" && user?.image ? (
                                        <img
                                          className="profile-avatar profile-image"
                                          src={user?.image}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          className="profile-avatar"
                                          src={images.profileIcon}
                                          alt=""
                                        />
                                      )}
                                    </ListItemIcon>
                                    <h2>{user.name}</h2>
                                  </MenuItem>
                                </RouterLink>
                              )}
                              <RouterLink to="/">
                                <MenuItem
                                  className={`${active == "/" && "active"}`}
                                  onClick={(e) => {
                                    handleCloseProfile(e);
                                    setActive("/");
                                  }}
                                  disableRipple
                                >
                                  Home
                                </MenuItem>
                              </RouterLink>
                              {isLoggedIn && user.role_id == "2" ? (
                                <>
                                  <RouterLink to="/account-settings">
                                    <MenuItem
                                      className={`${active == "/account-settings" && "active"
                                        }`}
                                      onClick={(e) => {
                                        handleCloseProfile(e);
                                        setActive("/account-settings");
                                      }}
                                      disableRipple
                                    >
                                      Account
                                    </MenuItem>
                                  </RouterLink>
                                  <RouterLink to="/bookings">
                                    <MenuItem disableRipple>Bookings</MenuItem>
                                  </RouterLink>
                                  <RouterLink to="/services">
                                    <MenuItem
                                      className={`${active == "/services" && "active"
                                        }`}
                                      onClick={(e) => {
                                        handleCloseProfile(e);
                                        setActive("/services");
                                      }}
                                      disableRipple
                                    >
                                      Services
                                    </MenuItem>
                                  </RouterLink>
                                </>
                              ) : (
                                <>
                                  <RouterLink to="/account-settings">
                                    <MenuItem
                                      onClick={handleCloseProfile}
                                      disableRipple
                                    >
                                      Account
                                    </MenuItem>
                                  </RouterLink>
                                  <RouterLink to="/bookings">
                                    <MenuItem
                                      disableRipple
                                      onClick={handleCloseProfile}
                                    >
                                      Bookings
                                    </MenuItem>
                                  </RouterLink>
                                  <RouterLink to="/favorites">
                                    <MenuItem
                                      className={`${active == "/favorites" && "active"
                                        }`}
                                      onClick={(e) => {
                                        handleCloseProfile(e);
                                        setActive("/favorites");
                                      }}
                                      disableRipple
                                    >
                                      Favorites
                                    </MenuItem>
                                  </RouterLink>
                                </>
                              )}

                              <Divider className="separation" />
                              <RouterLink to="/help">
                                <MenuItem
                                  onClick={handleCloseProfile}
                                  disableRipple
                                >
                                  <ListItemIcon className="profile-space">
                                    <img src={images.faqIcon} alt="" />
                                  </ListItemIcon>
                                  Help
                                </MenuItem>
                              </RouterLink>

                              {!isLoggedIn && (
                                <MenuItem
                                  onClick={(event) => {
                                    handleSignUpModal(event);
                                  }}
                                  disableRipple
                                >
                                  <ListItemIcon className="profile-space">
                                    <img src={images.colorprofileIcon} alt="" />
                                  </ListItemIcon>
                                  Sign Up
                                </MenuItem>
                              )}

                              {!isLoggedIn && (
                                <MenuItem
                                  onClick={(event) => {
                                    handleCloseProfile(event);
                                    handleOpenAuthModal(event);
                                  }}
                                  disableRipple
                                >
                                  <ListItemIcon className="profile-space">
                                    <img src={images.logoutIcon} alt="" />
                                  </ListItemIcon>
                                  Login
                                </MenuItem>
                              )}

                              {isLoggedIn && user.auth_type != "google" && (
                                <MenuItem
                                  onClick={(event) => {
                                    if (
                                      user.auth_type == "facebook" &&
                                      window.FB
                                    ) {
                                      window.FB.logout();
                                    }
                                    handleLogout();
                                    handleCloseProfile(event);
                                  }}
                                  disableRipple
                                >
                                  <ListItemIcon className="profile-space">
                                    <img src={images.logoutIcon} alt="" />
                                  </ListItemIcon>
                                  Log out
                                </MenuItem>
                              )}
                              {isLoggedIn && user.auth_type == "google" && (
                                <GoogleLogout
                                  clientId={clientId}
                                  buttonText="Log out"
                                  onLogoutSuccess={(response) => {
                                    handleLogout();
                                  }}
                                  cookiePolicy={"single_host_origin"}
                                  onFailure={(err) => {
                                    handleLogout();
                                  }}
                                  render={(renderProps) => (
                                    <MenuItem
                                      onClick={(event) => {
                                        handleCloseProfile(event);
                                        renderProps.onClick();
                                      }}
                                    >
                                      <ListItemIcon className="profile-space">
                                        <img src={images.logoutIcon} alt="" />
                                      </ListItemIcon>
                                      Log out
                                    </MenuItem>
                                  )}
                                ></GoogleLogout>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <AuthModal ref={authModalRef} />
        </Container>
      </header >
    </>
  );
};

export default Header;
