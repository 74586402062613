import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
    Container,
    Link,
    Typography,
    Box,
    Button
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Slider from "react-slick";
import { useSelector } from "react-redux";

import images from "../../assets/js/images";
import HeroBanner from "../../Components/Common/HeroBanner"
import SectionHeader from "../../Components/Common/SectionHeader";
import BeachGear from "../../Components/Home/BeachGear";
import Category from "../../Components/Home/Category";
import Beach from "../../Components/Home/Beach";
import ModalPopup from "../../Components/Modal/ModalPopup";
import { BeachGearSkeleton } from "../../Components/Skeleton/BeachGearSkeleton";
import { BeachSkeleton } from "../../Components/Skeleton/BeachSkeleton";

import { HomePageService } from "../../Services/HomePageService";

const popularCategory = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    // initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 414,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
    ]
};

const previouslyRented = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const previouslyViewed = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    centerPadding: '60px',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Home = props => {
    const location = useLocation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const [popularCategories, setPopularCategories] = useState([]);

    const [popularBGDatas, setPopularBGDatas] = useState({
        title: 'Popular beach gear',
        viewAllLink: '#',
        popularGears: []
    });

    const [nearByBeaches, setNearByBeaches] = useState({
        title: 'All beaches nearby',
        viewAllLink: '#',
        beaches: []
    });
    const [previousRented, setPreviouslyRented] = useState({
        title: 'Previously rented',
        viewAllLink: '#',
        previousRents: []
    });

    const [previousViewed, setPreviouslyViewed] = useState({
        title: 'Previously viewed',
        viewAllLink: '#',
        beaches: []
    });

    const [openChild, setOpenChild] = useState(false);
    const [loader, setLoader] = useState(false);
    const [beachLoader, setBeachLoader] = useState(false);


    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);
    useEffect(() => {
        if (user.role_id == '3') {
            getServiceListRandom();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        getHomeData();
        getBeachesListRandom();

        setTimeout(() => {
            document.querySelector('.popular-category .slick-prev')?.click();
        }, 1000);
        if (!isLoggedIn || (isLoggedIn && user.role_id == '2')) {
            getServiceListRandom();
        }
    }, []);

    const getServiceListRandom = () => {
        setLoader(true);

        HomePageService.serviceListRandom().then(res => {
            previousRented.previousRents = res.data.data;
            setPreviouslyRented(previousRented);

            popularBGDatas.popularGears = res.data.data.slice(0, 5);
            setPopularBGDatas(popularBGDatas);

            setLoader(false);
        })
    }

    const getBeachesListRandom = () => {
        setBeachLoader(true);

        HomePageService.beachesListRandom().then(res => {
            nearByBeaches.beaches = res.data.data;
            setNearByBeaches(nearByBeaches);

            previousViewed.beaches = res.data.data;
            setPreviouslyViewed(previousViewed);
            setBeachLoader(false);
        })
    }

    const getHomeData = () => {
        setPopularCategories([
            { img: images.category1, title: 'Badminton' },
            { img: images.category2, title: 'Beach Chair' },
            { img: images.category3, title: 'Umbrella' },
            { img: images.category4, title: 'Kayak (1 Person)' },
            { img: images.category5, title: 'Boogie board' },
            { img: images.category6, title: 'Stroller' },
        ]);
    }

    return (
        <>
            {location.pathname.includes("verified") &&
                <ModalPopup
                    open={true}
                    onClose={() => setOpenChild(false)}
                    content={<>
                        <Box className="modalheader" sx={{ marginBottom: 0 }}>
                            <Box className="modal-close-btn">
                                <RouterLink to="/">
                                    <Button size="small" disableRipple> <img src={images.closeIcon} alt="" /></Button>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Box className="modal-body message-modal">
                            <img src={images.restingIcon} alt="sucessful" />
                            <Typography component="h2">Account verification is complete!</Typography>
                            <Typography component="p">You can now login with your account</Typography>
                        </Box>
                    </>}
                />
            }

            <section className="hero-section">
                <Container>
                    <Grid container>
                        <HeroBanner settings={settings} />
                    </Grid>
                </Container>
            </section>

            {/* Popular BeachGear */}
            <section className="popular-beachgear-section section-padding">
                <Container>
                    <Grid container>
                        <SectionHeader title={popularBGDatas.title} imgSource={images.arrowIcon} sectionType="popularbeachgear" />
                        <Grid xs={12}>
                            {!loader ?
                                <div className="grid-5" style={{ minHeight: '400px' }}>
                                    {popularBGDatas?.popularGears?.length > 0 && popularBGDatas.popularGears.map((gData, gIndex) => <BeachGear key={gIndex} beachgear={gData} pagetype="popularBeach" />)}
                                </div>
                                :
                                <div className="grid-5" style={{ minHeight: '400px' }}>
                                    <BeachGearSkeleton />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </section>

            {/* Popular Categories */}
            <section className="section-padding">
                <Container>
                    <SectionHeader title={'Popular categories'} imgSource={images.arrowIcon} sectionType="popularcategories" />
                    <Slider {...popularCategory} className="popular-category">
                        {popularCategories.length && popularCategories.map((cData, cIndex) => <Category key={cIndex} {...cData} />)}
                    </Slider>
                </Container>
            </section>

            {/* All beaches nearby */}
            {/* <section className="section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }} columnSpacing={{ xs: 1, sm: 3, md: 3, lg: 5 }}>
                        <SectionHeader title={nearByBeaches.title} imgSource={images.arrowIcon} sectionType="allbeachesnearby"/>
                        {!beachLoader ? 
                            nearByBeaches.beaches?.length && nearByBeaches.beaches.map(bData =>
                                <Grid xs={12} sm={6} md={4} lg={4}>
                                    <Beach {...bData} />
                                </Grid>
                            )
                        :
                            <div className="grid-3" >
                                <BeachSkeleton/>
                            </div>
                        }
                        <Link
                            component="button"
                            variant="body2"
                            underline="none"
                            className="view-all-btn mobile-view-all-btn"
                            color="inherit"
                        >
                            View all
                            <img src={images.arrowIcon} alt="arrow" />
                        </Link>
                    </Grid>
                </Container>
            </section> */}

            {/* Previously rented */}
            {/* <section className="section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <SectionHeader title={previousRented.title} imgSource={images.arrowIcon} sectionType="previouslyrented"/>
                        <Grid xs={12} sx={{ py: 0 }}>
                            {!loader ?
                                    <Slider {...previouslyRented} className="previously-rented">
                                        {previousRented.previousRents?.length && previousRented.previousRents.map(prvData => <BeachGear beachgear={prvData} />)}
                                    </Slider>
                                : 
                                <div className="grid-5" style={{minHeight:'400px'}}>
                                    <BeachGearSkeleton/>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </section> */}

            {/* Previously viewed */}
            <section className="section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <SectionHeader title={previousViewed.title} imgSource={images.arrowIcon} sectionType="previouslyviewed" />
                        <Grid xs={12} sx={{ py: 0 }}>
                            {!beachLoader ?
                                <Slider {...previouslyViewed} className="previously-viewed">
                                    {previousViewed.beaches?.length && previousViewed.beaches.map((bData, bIndex) => <Beach key={bIndex} {...bData} />)}
                                </Slider>
                                :
                                <div className="grid-3" >
                                    <BeachSkeleton />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </>
    );
}

export default Home;
