import { useContext } from "react";
import { FormContext } from "../../../../Context/PaymentsContext";
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";

const Name = () => {
  const { formik } = useContext(FormContext);
  return (
    <>
      <Typography
        variant="h6"
        component="h2"
        className="sub-text tertiary-text label-bottom-space"
      >
        Name
      </Typography>
      <FormControl sx={{ width: "100%" }} className="input-style small-radius">
        <TextField
          placeholder="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="outline-input textfield-rouded-md input-text-style small-radius"
          InputProps={{
            sx: {
              "input::placeholder": {
                color: "#757575",
              },
            },
          }}
          sx={{
            borderRadius: "18px !important",
            ".MuiInputBase-input": {
              paddingLeft: "30px",
            },

            ".MuiInputBase-root": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
              "&.Mui-focused": {
                " .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#eee",
              },
            },
          }}
        />
      </FormControl>
      {formik.errors.name && formik.touched.name && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.name}
        </FormHelperText>
      )}
    </>
  );
};

export default Name;
