import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Container,
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    MenuItem,
    MenuList,
    Skeleton
} from '@mui/material';
import Slider from "react-slick";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FavoriteButton from "../../Components/BeachGear/FavoriteButton";

import ServiceOrder from "./ServiceOrder";
import images from "../../assets/js/images";
import BeachGear from "../../Components/Home/BeachGear";
import SectionHeader from "../../Components/Common/SectionHeader";
import QuantityInput from "../../Components/Inputs/QuantityInput";
import RadioInput from "../../Components/Inputs/RadioInput";
import { BeachGearSkeleton } from "../../Components/Skeleton/BeachGearSkeleton";
import { HoursofOperationSkeleton } from "../../Components/Skeleton/HoursofOperationSkeleton";

import AppHelper from "../../Helpers/AppHelper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ShareModal from "../../Components/Modal/ShareModal";
import { BeachGearHostService } from "../../Services/BeachGearHostService";
import { BookingService } from "../../Services/BookingService";
import { CartService } from "../../Services/CartService";

const previouslyRented = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const ServiceDetail = () => {
    const params = useParams();
    const { start_date, end_date } = useSelector((state) => state.filterReducers.data);
    const navigate = useHistory();
    const { pathname } = useLocation();
    const [openOrder, setOpenOrder] = React.useState(false);
    const [quantity, setQuantity] = useState(1);
    const [priceData, setPriceData] = useState([]);
    const [priceData1, setPriceData1] = useState([]);
    const [selectedPriceData, setSelectedPriceData] = useState('');
    const [dayOption, setDayOption] = useState(0);
    const [openShare, setOpenShare] = React.useState(false);
    const [calculation, setCalculation] = React.useState({});

    const [loader, setLoader] = useState(false);
    const [serviceData, setServiceData] = useState({});
    const getServiceDetail = () => {
        if (params.slug != null) {
            setLoader(true);
            BeachGearHostService.serviceDetail(params.slug).then(res => {
                var response = res.data.data;
                var pricedatas = AppHelper.sortedPrice(response.price);
                if (pricedatas[0]) setSelectedPriceData(pricedatas[0].id);

                setPriceData(pricedatas);
                setPriceData1(AppHelper.sortedPrice(response.price, 1));
                setServiceData(response);
                setLoader(false);
            }).catch((error) => {
                //navigate.push('/');
            });
        }
    }

    const [cartList, setCartList] = React.useState({});
    const [cartItem, setCartItem] = React.useState({});
    const getCartList = () => {
        CartService.list().then(res => {
            if (res.data.data) {
                setCartList(res.data.data);

                var item = res.data.data.items.find(o => o.id == serviceData.id);
                setCartItem(item);
                setQuantity(item.quantity);
            }
        }).catch(error => {
        });
    };

    useEffect(() => {
        if (isLoggedIn) {
            getCartList();
        }
    }, [serviceData]);


    const [startTime, setStartTime] = React.useState('');
    const [endTime, setEndTime] = React.useState('');
    const getCalculation = (id) => {
        if(id){
            let startdatetime, enddatetime;
            
            if(cartItem==undefined || Object.keys(cartItem).length==0){
                var optionitem = priceData[0].pricetype.toLowerCase();
                var optionvalue = priceData[0].priceoption;

                var setstarttime = optionitem=='days' || start_date!=end_date ? "10:00 AM" : AppHelper.convertDateTime('', 5).add(5, 'minutes').format("hh:mm A");
                var setendtime = optionitem=='days' || start_date!=end_date ? "05:00 PM" : AppHelper.convertDateTime('', 5).add(5, 'minutes').add(optionvalue, optionitem).format("hh:mm A");
                
                setStartTime(setstarttime);
                setEndTime(setendtime);
                
                startdatetime = AppHelper.dateTimeFormat(setstarttime, start_date);
                enddatetime = AppHelper.dateTimeFormat(setendtime, end_date ? end_date : start_date );
            }else{
                setStartTime(cartItem.start_datetime);
                setEndTime(cartItem.end_datetime);

                startdatetime = cartItem.start_datetime;
                enddatetime = cartItem.end_datetime;
            }

            const postValues = {
                "start_date" : start_date,
                "end_date" : end_date ? end_date : start_date,
                "start_datetime": startdatetime,
                "end_datetime": enddatetime,
                "quantity" : quantity,
                "price" : selectedPriceData
            };
            
            BookingService.calculation(postValues, id).then(res => {
                var result = res.data.data;
                if (result.availablity_status == '1') {
                    setCalculation(result);
                }
            }).catch((error) => {
            });
        }
    }

    const dispatch = useDispatch();
    const { isLoggedIn, user } = useSelector((state) => state.userReducers.data);

    const [suggestedService, setSuggestedService] = useState({});
    const getSuggestedService = () => {
        if (params.slug != null) {
            setLoader(true);
            BeachGearHostService.suggestedService(params.slug).then(res => {
                var response = res.data.data;
                setSuggestedService(response);
                setLoader(false);
            }).catch((error) => {
                //navigate.push('/');
            });
        }
    }

    useEffect(() => {
        getServiceDetail();
        getSuggestedService();
        const handleScroll = () => {
            if (document.readyState === 'complete') {
                window.scrollTo(0, 0);
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname]);

    const calculationUpdate = useSelector((state) => state.calculationReducers);
    const calculatePrice = () => {
        const hasPricetypeDays = priceData.some(item => item.pricetype === "Days");
        
        if((start_date || end_date) && quantity > 0 && (hasPricetypeDays || (start_date == end_date))){
            (start_date == end_date) ? setDayOption(1) : setDayOption(0)
            getCalculation(serviceData.id);
            setPriceData(priceData)
            setPriceData1(priceData1)
        }
    }

    useEffect(() => {
        calculatePrice()
    }, [quantity, priceData, cartItem, selectedPriceData, calculationUpdate]);


    const openOrderModal = () => {
        if (isLoggedIn) {
            if (cartList.hostslug && cartList.hostslug != serviceData.host_slug) {
                AppHelper.cartMultipleHostWarning(setOpenOrder, setCartList, dispatch)
            } else {
                setOpenOrder(true);
            }
        } else {
            dispatch({
                type: "OPEN_AUTH_MODAL",
            });
        }
    }

    const handleBack = () => {
        navigate.go(-1);
    }

    useEffect(() => {
        window.onload = () => {
            window.FB && window.FB.XFBML && window.FB.XFBML.parse();
        };
    }, []);

    const locations = useLocation();
    const searchParams = new URLSearchParams(locations.search);
    const searchresultparam = searchParams.get("searchresult");

    return (
        <>
            <section className='service-detail service-listing section-padding'>
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid xs={12}>
                            <BreadCrumb data={[[`${serviceData.title}`]]} />
                            <Grid item xs={12} style={{ marginTop : "10px", marginBottom: '10px', padding: '0px', }}>
                                <Box className="top-side-heading">
                                    <div className="left-back-arrow ">
                                        <Link
                                            component="button"
                                            variant="body2"
                                            underline="none"
                                            className="tertiary-text"
                                            color="inherit"
                                            to="#"
                                            style={{ background: 'none', paddingLeft: '0px', border: 'none' }}
                                            onClick={handleBack}
                                        >
                                            <img src={images.backIcon} alt="" />
                                            Back
                                        </Link>
                                    </div>
                                </Box>
                            </Grid>
                            <Box className='top-heading'>
                                {loader ?
                                    <>
                                        <Skeleton variant="rounded" sx={{ width: '550px', height: '60px', '@media (min-width: 0) and (max-width: 991px)': { width: '100%' } }} />
                                    </>
                                    :
                                    <>
                                        <div className="main-heading-block">
                                            <h2 className="main-heading" sx={{
                                                marginBottom: '1px !important',
                                                marginTop: '0 !important',
                                            }}>{serviceData.title} <span style={{ marginLeft: '0' }} >({serviceData.category})</span>

                                            </h2>
                                            <Box className='heading-right'>
                                                <img src={images.UploadIcon2} alt="upload2" className='upload-right-space' onClick={() => { setOpenShare(true); }} />
                                                {user.role_id == "3" ? (
                                                    <FavoriteButton
                                                        elementData={serviceData}
                                                        isFavorite={serviceData.isFavorite}
                                                    />
                                                ) : (
                                                    <></>
                                                )}

                                            </Box>
                                        </div>
                                    </>
                                }
                            </Box>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid className="detail-grid-1" lg={8} md={8} xs={12} sm={12}>
                                <Box>
                                    {loader
                                        ?
                                        <Skeleton variant="rounded" width="100%">
                                            <div style={{ paddingTop: '57%' }} />
                                        </Skeleton>
                                        :
                                        <img src={serviceData?.featureImg?.imgPath} style={{ width: '756px', height: '504px' }} className='border-radius-md object-cover detail-grid-image' alt={serviceData.title + " | Beachify"} />
                                    }
                                    <Typography variant="h2" className="servicedetail_description_title">
                                        Highlights
                                    </Typography>
                                    <Box className="servicedetail_description">
                                        <Typography variant="h6" component="p" className="">
                                            {serviceData.description}
                                        </Typography>
                                    </Box>

                                    <Box className="section_hoursofoperation">
                                        {loader ?
                                            <Skeleton variant="rounded" sx={{ width: '50%', height: '25px', marginBottom: '15px', '@media (min-width: 0) and (max-width: 575.98px)': { width: '100%' } }} />
                                            :
                                            <h3 className="hoop_title">Business Hours</h3>
                                        }
                                        {loader ?
                                            <HoursofOperationSkeleton />
                                            :
                                            serviceData?.hoursOfOperation?.map((v, i) => (
                                                <div className="hoop_content" key={i}>
                                                    <div className="hoop_day">{v.day}</div>
                                                    <div className="hoop_time">{v.status == 1 ? (v.open + ' - ' + v.close) : '-'}</div>
                                                </div>
                                            ))}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid className="detail-grid-2" lg={4} md={4} xs={12} sm={12}>
                                <Box >
                                    {loader ?
                                        <>
                                            <Box className="host-img">
                                                <Skeleton variant="circular" width={60} height={60} sx={{ flexShrink: '0' }} />
                                                <Skeleton variant="rounded" sx={{ width: '150px', height: '20px', marginLeft: '5px', '@media (min-width: 0) and (max-width: 575.98px)': { width: '100%' } }} />
                                            </Box>
                                        </>
                                        :
                                        <Link to={'/host/' + serviceData.host_slug}>
                                            <Box className="host-img">
                                                <Box className="small-logo">
                                                    <img src={serviceData.profile_image ? serviceData.profile_image : images.smallogo} alt="" style={{ borderRadius: "50%" }} />
                                                </Box>
                                                <h2 className="host-heading">
                                                    {serviceData.companyName}
                                                </h2>
                                            </Box>
                                        </Link>
                                    }
                                    <Box className="host-listing">
                                        <List className='feature-listing'>
                                            <ListItem >
                                                <ListItemIcon className="feature-listing-icon">
                                                    <img src={images.distanceIcon} alt="distance" className="img-color" />
                                                </ListItemIcon>
                                                {loader
                                                    ?
                                                    <Skeleton variant="rounded" sx={{ width: '150px', height: '20px', marginLeft: '5px', '@media (min-width: 0) and (max-width: 575.98px)': { width: '250px' } }} />
                                                    :
                                                    <ListItemText
                                                        className="feature-list-name black-text" primary={serviceData.distance + "m from you"}
                                                    />
                                                }
                                            </ListItem>
                                            {serviceData.instantBookingAvailable ?
                                                <ListItem >
                                                    <ListItemIcon className="feature-listing-icon">
                                                        <img src={images.calenderIcon1} alt="calender" className="img-color" />
                                                    </ListItemIcon>
                                                    {loader
                                                        ?
                                                        <Skeleton variant="rounded" sx={{ width: '200px', height: '20px', marginLeft: '5px', '@media (min-width: 0) and (max-width: 575.98px)': { width: '100%' } }} />
                                                        :
                                                        <ListItemText className="feature-list-name black-text" primary="Instant booking" />
                                                    }
                                                </ListItem>
                                                :
                                                <></>
                                            }
                                            {serviceData.deliveryPickupService ?
                                                <ListItem >
                                                    <ListItemIcon className="feature-listing-icon">
                                                        <img src={images.car1} alt="car" className="img-color" />
                                                    </ListItemIcon>
                                                    {loader
                                                        ?
                                                        <Skeleton variant="rounded" width={150} height={20} style={{ marginLeft: 5 }} />
                                                        :
                                                        <ListItemText className="feature-list-name black-text" primary="Delivery & Pickup" />
                                                    }
                                                </ListItem>
                                                :
                                                <></>
                                            }
                                        </List>
                                    </Box>
                                    <Box class="section_pricelist">
                                        <RadioInput
                                            customArr={{ data: priceData, name: 'name', id: 'id'  }}
                                            dayOption={dayOption}
                                            dayOptionStatus={start_date!='' && end_date!=''}
                                            name="price"
                                            value={selectedPriceData}
                                            onChange={(e) => {
                                                setSelectedPriceData(e.target.value)
                                            }}
                                            sx={{
                                                fontSize: '16px',
                                                paddingRight: '30px',
                                                width: '100%'
                                            }}
                                        />
                                    </Box>
                                    {!isLoggedIn || user.role_id == "3" ?
                                        <Box className="small-box">
                                            <MenuList
                                                autoFocusItem={open}
                                                id="search-bear-menu"
                                                aria-labelledby="search-bear-button"
                                                // anchorOrigin={{
                                                //     vertical: 'bottom',
                                                //     horizontal: 'left',
                                                // }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                sx={{ py: 0 }}
                                            >
                                                <MenuItem className="backgrond-remove"
                                                    sx={{
                                                        "&:hover": { backgroundColor: "transparent", },
                                                        padding: '0'
                                                    }}
                                                >
                                                    <Box component="div" className="beachgear-value-input amount-added">
                                                        <p>Quantity</p>
                                                        <QuantityInput
                                                            decrement={() => {
                                                                if (quantity >= 2) {
                                                                    setQuantity(quantity - 1);
                                                                }
                                                            }}
                                                            quantity={quantity}
                                                            increment={() => {
                                                                if (quantity >= 1) {
                                                                    setQuantity(quantity + 1);
                                                                }
                                                            }}
                                                        />

                                                    </Box>
                                                </MenuItem>
                                            </MenuList>
                                            <Box className='dotted-border-2'></Box>
                                            <Box className="cart-summary">
                                            {Object.keys(calculation).length
                                                ?
                                                <>
                                                <List component="div" disablePadding>
                                                    {calculation.hasOwnProperty("item_charge")
                                                        ?
                                                        <ListItemText className="summary-price">Subtotal <span>{AppHelper.currency()}{calculation.item_charge}</span></ListItemText>
                                                        :
                                                        <></>
                                                    }
                                                    {calculation.hasOwnProperty("security_deposit")
                                                        ?
                                                        <ListItemText className="summary-price">Security Deposit <span>{AppHelper.currency()}{calculation.security_deposit}</span></ListItemText>
                                                        :
                                                        <></>
                                                    }
                                                    {calculation.hasOwnProperty("service_charge")
                                                        ?
                                                        <ListItemText className="summary-price">Beachify Service Fee : <span>{AppHelper.currency()}{calculation.service_charge}</span></ListItemText>
                                                        :
                                                        <></>
                                                    }
                                                    {calculation.hasOwnProperty("total")
                                                        ?
                                                        <ListItemText className="summary-price summary-price-total">Total <span>{AppHelper.currency()}{calculation.total}</span></ListItemText>
                                                        :
                                                        <></>
                                                    }
                                                </List>
                                                <Box className='dotted-border-2'></Box>
                                                </>
                                                :
                                                <></>
                                            }
                                            </Box>
                                            <Box className="total-order-btn">
                                                {(serviceData.quantity > 0 || serviceData.categoryId != 1) ?
                                                    <Button
                                                        size="small"
                                                        className="btn-primary border-radius-md order-btn"
                                                        onClick={() => openOrderModal()}
                                                    >
                                                        Add to Order
                                                    </Button>
                                                    :
                                                    <Button
                                                        size="small"
                                                        className="btn-primary border-radius-md order-btn"
                                                    >
                                                        Out of Stock
                                                    </Button>
                                                }
                                            </Box>
                                        </Box>
                                        :
                                        <></>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="section-padding">
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <SectionHeader title={"You may be interested"} imgSource={images.arrowIcon} sectionType="suggestedservice" />
                        <Grid xs={12} sx={{ py: 0 }}>
                            {!loader ?
                                <Slider {...previouslyRented} className="previously-rented">
                                    {suggestedService.length && suggestedService.map((prvData, prvIndex) => <BeachGear key={prvIndex} beachgear={prvData} />)}
                                </Slider>
                                :
                                <div className="grid-5" style={{ minHeight: '400px' }}>
                                    <BeachGearSkeleton />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Container>
                {
                    Object.keys(serviceData).length && openOrder
                    ?
                    <>

                        <ServiceOrder
                            open={openOrder}
                            setOpen={setOpenOrder}
                            beachGear={serviceData}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            startTimeValue={startTime}
                            endTimeValue={endTime}
                            priceData={priceData1}
                            selectedPriceData={selectedPriceData}
                            setSelectedPriceData={setSelectedPriceData}
                            cartItem={cartItem}
                            dayOption={dayOption}
                            setDayOption={setDayOption}
                            searchresult={searchresultparam}
                        />
                    </>
                    :
                    <></>
                }
            </section>
            {
                openShare
                ?
                <ShareModal openShare={openShare} setOpenShare={setOpenShare} title={serviceData.title} image={serviceData?.featureImg?.imgPath} url={window.location.href} />
                :
                <></>
            }
        </>
    )
}

export default ServiceDetail;
