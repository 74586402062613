import { Box, Typography } from "@mui/material";
import ReviewCard from "./ReviewCard";
import { ReviewsContext } from "../../Context/ReviewsContext";
import { useContext } from "react";
import LoadingReview from "./LoadingReview";
const exampleReviews = [
  {
    name: "Bart Cassidy",
    date: "April 2021",
    comment: "This is an example review on bechify",
  },
  {
    name: "Example user",
    date: "November 2022",
    comment: "This is other example review on bechify",
  },
];
const ReviewsAboutYouTab = () => {
  const { loading, reviews } = useContext(ReviewsContext);

  return (
    <Box sx={{ padding: "0px !important" }}>
      {loading ? (
        <LoadingReview />
      ) : (
        <>
          <Typography variant="h3" sx={{ fontWeight: "600" }}>
            Past Reviews
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            Reviews are written at the end of a reservation through Bachify.
            Reviews you've received will be visible both here and on your public
            profile.
          </Typography>
          {reviews.aboutYou.map((review, index) => (
            <ReviewCard key={index} review={review} userField="author" />
          ))}
        </>
      )}
    </Box>
  );
};

export default ReviewsAboutYouTab;
