import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";
import CreateModal from "./CreateModal";
import { CouponService } from "../../../Services/CouponService";
import MakeToast from "../../../Helpers/MakeToast";
import CouponsList from "./CouponsList";
import { CouponContext } from "../../../Context/CouponContext";
const CouponsSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCoupons = () => {
    CouponService.get()
      .then((response) => {
        setLoading(false);
        setCoupons(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        MakeToast("error", error);
      });
  };
  useEffect(() => {
    getCoupons();
  }, []);
  return (
    <CouponContext.Provider
      value={{
        setCoupons: setCoupons,
      }}
    >
      <Typography sx={{ fontWeight: "600", fontSize: 25 }}>Coupons</Typography>
      <Typography
        sx={{
          fontWeight: "light",
          fontSize: 18,
          mb: 3,
        }}
      >
        Add a coupon and save on your next trip.
      </Typography>
      <Box
        sx={{
          borderBottom:
            coupons?.length > 0
              ? "none"
              : "1px solid rgb(221, 221, 221) !important",
          borderTop: "1px solid rgb(221, 221, 221) !important",
          py: 2,
        }}
      >
        {loading ? <Skeleton height={30} /> : <CouponsList coupons={coupons} />}
      </Box>

      <Button
        size="small"
        type="button"
        sx={{
          backgroundColor: "black !important",
          mt: 3,
          mb: 3,
          borderRadius: 16,
          border: "1px solid black !important",
        }}
        className="btn-primary submit-btn "
        onClick={() => setShowModal(true)}
      >
        Add Coupon
      </Button>
      <CreateModal open={showModal} onClose={() => setShowModal(false)} />
    </CouponContext.Provider>
  );
};

export default CouponsSection;
