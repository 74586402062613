import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import {
    Link,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    Box,
    Typography,
    Input,
    InputAdornment,
    FormControl
} from "@mui/material";
import debounce from 'lodash.debounce';
import { HomePageService } from "../../Services/HomePageService";
import AppHelper from "../../Helpers/AppHelper";
import CheckboxInput from "../Inputs/CheckboxInput";
import images from "../../assets/js/images";

const LocationPopover = ({ anchorLocationEl, handleCloseLocation, clearAllData }) => {
    const navigate = useHistory();
    const location = useLocation();

    const [beachList, setBeachList] = useState([]);
    const [searchBeach, setSearchBeach] = useState('');
    const [checkedBeachValue, setCheckedBeachValue] = useState(null);

    const locationSearch = useRef(null);
    const dispatch = useDispatch();

    const getBeachList = (search = '', id = '') => {
        HomePageService.searchBeach(search, id).then(res => {
            var response = res.data.data;
            setBeachList(response);

            if (id != '' && response.length) {
                setCheckedBeachValue(response[0].id)
                setCheckedBeachName(response[0].name)
            }
        })
    }

    const locationOpen = Boolean(anchorLocationEl);
    const debounceBeach = debounce((value) => {
        getBeachList(value);
    }, 1000);

    React.useEffect(() => {
        var searchStorage = sessionStorage.getItem('searchItems') != null ? JSON.parse(sessionStorage.getItem('searchItems')).data : {};

        if (searchStorage.beach_id != '') {
            getBeachList('', searchStorage.beach_id);
        }

        setTimeout(() => {
            if (locationSearch != null && locationSearch.current) locationSearch.current.focus();
        }, 500);
    }, [locationOpen]);

    const saveSearch = (e) => {
        if (location.pathname == '/search-result') {
            handleCloseLocation(e)
            navigate.push('/search-result?search=' + AppHelper.searchRandomNumber());
            return;
        }

        navigate.push('/search-result');
    }

    const setCheckedBeachName = (value) => {
        var searchLocationBox = document.querySelectorAll('.searchLocationBox');
        for (var n = 0; n < searchLocationBox.length; n++) {
            searchLocationBox[n].querySelector('input').value = value;
        }
    }

    const clearAll = () => {
        setCheckedBeachValue(null);
        setCheckedBeachName('')
        dispatch({
            type: 'SET_BEACH',
            payload: null,
        });
    }

    React.useEffect(() => {
        if (clearAllData == 1) {
            clearAll()
        }
    }, [clearAllData]);

    return (
        <>
            <Popper
                open={locationOpen}
                anchorEl={anchorLocationEl}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal={false}
                className="filter-popper"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom-start" ? "left top" : "left bottom" }}
                    >
                        <Paper className="filter-popper-paper">
                            <ClickAwayListener onClickAway={handleCloseLocation}>
                                <div>
                                    <Scrollbars
                                        className="filter-scrollbar"
                                        autoHeight
                                        autoHeightMin={0}
                                        autoHeightMax={450}
                                    >
                                        <Box className="search_filter">
                                            <FormControl
                                                variant="standard"
                                                className="input-style outlined-input w-100"
                                                underline="none"
                                            >
                                                <Input
                                                    id="input-with-icon-adornment"
                                                    inputRef={locationSearch}
                                                    placeholder="Search"
                                                    underline="none"
                                                    disableUnderline={true}
                                                    onChange={(e) => {
                                                        setSearchBeach(e.target.value);
                                                        debounceBeach(e.target.value);
                                                    }}
                                                    value={searchBeach}
                                                    startAdornment={
                                                        <InputAdornment
                                                            position="start"
                                                            className="input-image"
                                                            underline="none"
                                                            sx={{ pl: 1.5, pr: 1 }}
                                                        >
                                                            <img
                                                                src={images.searchIcon}
                                                                alt="search"
                                                            />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <MenuList
                                                id="search-location-menu"
                                                aria-labelledby="search-location-button"
                                                // anchorOrigin={{
                                                //     vertical: "bottom",
                                                //     horizontal: "left",
                                                // }}
                                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                sx={{ padding: "0 !important" }}
                                            >
                                                {beachList.length > 0 ?
                                                    beachList.map((cData, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                sx={{
                                                                    "&:hover": {
                                                                        backgroundColor: "transparent",
                                                                    },
                                                                }}
                                                            >
                                                                <Box
                                                                    component="div"
                                                                    className="location-value-input"
                                                                >
                                                                    <Box className="location-value">
                                                                        <img
                                                                            src={cData.img}
                                                                            className="border-radius-12"
                                                                            alt="beach-image"
                                                                        />
                                                                        <Box
                                                                            component="label"
                                                                            className="text-truncate"
                                                                        >
                                                                            {cData.name}
                                                                        </Box>
                                                                    </Box>
                                                                    <CheckboxInput
                                                                        inputtype="checkboxonly"
                                                                        checked={
                                                                            cData.id == checkedBeachValue
                                                                        }
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setCheckedBeachValue(cData.id);
                                                                                setCheckedBeachName(cData.name)
                                                                                dispatch({
                                                                                    type: 'SET_BEACH',
                                                                                    payload: cData.id,
                                                                                });
                                                                            } else {
                                                                                setCheckedBeachValue(null);
                                                                                setCheckedBeachName('')
                                                                                dispatch({
                                                                                    type: 'SET_BEACH',
                                                                                    payload: null,
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </MenuItem>
                                                        );
                                                    })
                                                    :
                                                    <Typography sx={{
                                                        textAlign: 'center'
                                                    }}>No Records Found</Typography>
                                                }
                                            </MenuList>
                                        </Box>
                                    </Scrollbars>
                                    <Box className="filter-menu-footer">
                                        <Link
                                            onClick={clearAll}
                                            href="javascript:void(0);"
                                            className="link-secondary text-underline clear-btn"
                                        >
                                            Clear all
                                        </Link>
                                        <Link
                                            variant="contained"
                                            className="border-radius-md btn-primary save-btn"
                                            onClick={saveSearch}
                                            style={{
                                                padding: '6px 16px',
                                                display: 'inline-flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: '16px'
                                            }}
                                        >
                                            Save & Search
                                        </Link>
                                    </Box>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default LocationPopover;