import { useFormik } from "formik";
import ValidationSchema from "./Form/ValidationSchema";
import InitialValues from "./Form/InitialValues";
import { FormContext } from "../../../Context/PaymentsContext";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Name from "./Form/Name";
import DurationType from "./Form/DurationType";
import DurationMonths from "./Form/DurationMonths";
import Amount from "./Form/Amount";
import { CouponService } from "../../../Services/CouponService";
import MakeToast from "../../../Helpers/MakeToast";
import { useContext } from "react";
import { CouponContext } from "../../../Context/CouponContext";

const Form = ({ closeModal }) => {
  const { setCoupons } = useContext(CouponContext);

  const handleSubmit = async (values) => {
    await CouponService.store(values)
      .then((response) => {
        setCoupons(response.data.data);
        closeModal();
        MakeToast("success", response);
      })
      .catch((error) => MakeToast("error", error));
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: InitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormContext.Provider value={{ formik: formik }}>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Name />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DurationType />
        </Grid>
        {formik.values.duration_type == "repeating" && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DurationMonths />
          </Grid>
        )}
        <Amount />
      </Grid>
      <Button
        size="small"
        type="button"
        disableRipple
        disableElevation
        className="btn-primary submit-btn rounded-button"
        style={{ marginTop: "15px" }}
        sx={{
          backgroundColor: "black !important",
          border: "solid 1px black !important",
        }}
        disabled={formik.isSubmitting}
        onClick={(e) => formik.handleSubmit()}
      >
        {formik.isSubmitting ? (
          <CircularProgress color="inherit" size={25} />
        ) : (
          "Save"
        )}
      </Button>
    </FormContext.Provider>
  );
};

export default Form;
