// import { Breadcrumbs, Link, Typography,Box} from "@mui/material";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { Link as RouterLink } from "react-router-dom";
// import { useHistory } from "react-router-dom";

// const BreadCrumb = (props) => {
//     const navigate = useHistory();
//     return (
//     <>
//         <Breadcrumbs
//           className="custom_breadcrumb"
//           separator={<NavigateNextIcon fontSize="small" />}
//           aria-label="breadcrumb"
//           sx={props.sx}
//         >
//             <RouterLink to="/">
//                 <Typography>Home</Typography>
//             </RouterLink>

//             {props.data.map((v, i) => {
//             return (
//                 v[0]!='undefined'
//                 ?
//                 (
//                     i!=props.data.length-1
//                     ?
//                     (
//                         v[1]=='goback' 
//                         ?
//                         <RouterLink key={i} onClick={() => { navigate.goBack() }}>
//                             <Typography>{v[0]}</Typography>
//                         </RouterLink>
//                         : 
//                         <RouterLink key={i} to={v[1]}>
//                             <Typography>{v[0]}</Typography>
//                         </RouterLink>
//                     )
//                     :
//                     <Link key={i}>{v[0]}</Link>   
//                 ) 
//                 :
//                 <></>
//             );
//             })}
//         </Breadcrumbs>
//     </>
//     );
// };

// export default BreadCrumb;

import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const BreadCrumb = (props) => {
  const navigate = useHistory();
  
  const breadcrumbItems = [
    <RouterLink to="/" key="home">
      <Typography>Home</Typography>
    </RouterLink>,
    ...props.data.map((v, i) => {
      if (v[0] !== 'undefined') {
        if (i !== props.data.length - 1) {
          if (v[1] === 'goback') {
            return (
              <RouterLink key={i} onClick={() => { navigate.goBack(); }}>
                <Typography>{v[0]}</Typography>
              </RouterLink>
            );
          } else {
            return (
              <RouterLink key={i} to={v[1]}>
                <Typography>{v[0]}</Typography>
              </RouterLink>
            );
          }
        } else {
          return <Link key={i}>{v[0]}</Link>;
        }
      }
      return null;
    })
  ];

  return (
    <Breadcrumbs
      className="custom_breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={props.sx}
    >
      {breadcrumbItems}
    </Breadcrumbs>
  );
};

export default BreadCrumb;

