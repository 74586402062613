import { useContext } from "react";
import { FormContext } from "../../../../Context/PaymentsContext";
import {
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const DurationType = () => {
  const { formik } = useContext(FormContext);
  return (
    <>
      <Typography
        variant="h6"
        component="h2"
        className="sub-text tertiary-text label-bottom-space"
      >
        Duration Type
      </Typography>
      <FormControl
        variant="standard"
        className="input-style outlined-input select-style textfield-rouded-md"
        sx={{
          width: "100%",
          borderRadius: "18px",
          mb: 1,
          pl: 4,
          mr: 4,
        }}
      >
        <Select
          sx={{
            "&.MuiInput-root:before": {
              borderBottom: "none !important",
            },
          }}
          input={<Input />}
          value={formik.values.duration_type}
          onChange={(event) =>
            formik.setFieldValue("duration_type", event.target.value)
          }
        >
          <MenuItem value="forever">Forever</MenuItem>
          <MenuItem value="once">Once</MenuItem>
          <MenuItem value="repeating">Multiple months</MenuItem>
        </Select>
      </FormControl>
      {formik.errors.duration_type && formik.touched.duration_type && (
        <FormHelperText className="field-helper-text error">
          {formik.errors.duration_type}
        </FormHelperText>
      )}
    </>
  );
};

export default DurationType;
