import { APIService } from "./APIService";

export const CouponService = {
  get,
  store,
  remove,
};

function get() {
  return APIService.get("api/account_settings/host/coupons");
}
function store(data) {
  return APIService.post("api/account_settings/host/coupons", data);
}
function remove(data) {
  return APIService.post("api/account_settings/host/coupons/remove", data);
}
