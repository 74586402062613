import React from "react";
import { Box, Typography, Popover } from "@mui/material";
import { HelpOutline } from '@mui/icons-material';
import parse from 'html-react-parser'

const QuestionMarkPopover = ({ anchorPopOver, setAnchorPopOver, content, spanClassName, spanStyle, iconStyle }) => {
    const openPopOver = Boolean(anchorPopOver);
    
    return (
        <>
            <Box
                component="span"
                onClick={(e) => { setAnchorPopOver(e.currentTarget); }}
                className={ spanClassName } 
                sx={{ cursor: "pointer"}}
                style={spanStyle} 
            >
                <HelpOutline 
                    sx={{ fontSize: '27px', marginLeft: '10px'}}
                    style={iconStyle} 
                />
            </Box>
            <Popover
                open={openPopOver}
                anchorEl={anchorPopOver}
                onClose={(e) => { setAnchorPopOver(null); }}
                // anchorOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'left',
                // }}
            >
                <Typography sx={{ p: 2 }}>
                    { parse(content) }
                </Typography>
            </Popover>
        </>
    );
}

export default QuestionMarkPopover;