import React from 'react'
import { useHistory } from "react-router-dom";
import {
    Box,
    Button,
    Typography,
    Divider,
    FormControl,
    TextField,
    MenuList,
    MenuItem,
    FormHelperText,
    InputAdornment,
    List,
    ListItemText,
    CircularProgress,
    Input,
    Skeleton,
} from '@mui/material';
import moment from "../../Helpers/MomentDefaultHelper";
import images from "../../assets/js/images";
import { useFormik } from 'formik';
import * as yup from 'yup';
import parse from 'html-react-parser'
import { useDispatch,useSelector } from "react-redux";

import MakeToast from "../../Helpers/MakeToast";
import AppHelper from '../../Helpers/AppHelper';
import RadioInput from '../../Components/Inputs/RadioInput';
import TimeInput from '../../Components/Inputs/TimeInput';
import QuantityInput from '../../Components/Inputs/QuantityInput';
import GoogleAutoCompleteInput from '../../Components/Inputs/GoogleAutoCompleteInput';
import ModalPopup from "../../Components/Modal/ModalPopup";

import { BookingService } from "../../Services/BookingService";
import { CartService } from "../../Services/CartService";
import { FavoriteService } from "../../Services/FavoriteService";
import RangePicker from '../../Components/Inputs/RangePicker';
import Grid from '@mui/material/Unstable_Grid2';

let calculationInterval;
const ServiceOrder = ({ open, setOpen, beachGear, quantity, setQuantity, startTimeValue, endTimeValue, priceData, selectedPriceData, setSelectedPriceData, cartItem, pageType, dayOption, setDayOption, searchresult }) => {
    const [priceType, setPriceType] = React.useState(null);
    const [priceOption, setPriceOption] = React.useState(null);
    const getPriceTypeOption = () => {
        var selectedpriceobj = priceData.find(o => o.id == selectedPriceData)
        if(selectedpriceobj){
            setPriceType(selectedpriceobj.pricetype)
            setPriceOption(selectedpriceobj.priceoption)
        }
    }

    const navigate = useHistory();
    const dispatch = useDispatch();
    const { start_date, end_date } = useSelector((state) => state.filterReducers.data);
    const handleClose = () => setOpen(false);
    const intervalOfHours = (startTime, endTime) => {
        let start = moment(startTime);
        let end = moment(endTime);
        
        if(start.format('Y-MM-DD')==end.format('Y-MM-DD')){
            let diff = end.diff(start, 'minutes');
            if (priceType == "Hours") {
                return (diff / 60) % priceOption === 0;
            } else if (priceType == "Minutes") {
                return (diff) % priceOption === 0;
            }
        }
        
        return true;
    };

    const yesno = AppHelper.lists().yesno;
    const [startDate, setStartDate] = React.useState(moment().format("MM-DD-YYYY"));
    const [endDate, setEndDate] = React.useState(moment().format("MM-DD-YYYY"));
    const [deliveryAddressData, setDeliveryAddressData] = React.useState({})
    const [pickupAddressData, setPickupAddressData] = React.useState({})
    const [deliveryPickupDisable, setDeliveryPickupDisable] = React.useState(false)
    const [deliveryPickupOption, setDeliveryPickupOption] = React.useState('')

    const validationSchema = yup.object().shape({
        start_date: yup.string()
            .test(
                "host_availablity",
                "Host is not open for business for the start date you selected",
                (val, data) => {
                    return startHOP?.status;
                }),
        end_date: yup.string()
            .test(
                "host_availablity",
                "Host is not open for business for the end date you selected",
                (val, data) => {
                    return endHOP?.status;
                }),
        start_time: yup.string()
            .test("starttime", "Start Time is required", (val) => {
                return val != undefined && val.replace(/:| |_/g, "").length === 6 ? true : false;
            })
            .test("startcurrenttime", "Start Time must be greater than current time", (val, data) => {
                return startEndCurrentTimeValidation(data.parent, 1);
            })
            .test("startendtime", "", (val, data) => {
                return startEndValidation(data.parent)
            })
            .test("startendtime", "Host is not open for business for the start time you selected.", (val, data) => {
                return hoursOfOperationStartEndValidation(data.parent.start_datetime)
            }),
        end_time: yup.string()
            .test("endtime", "End Time is required", (val) => {
                return val != undefined && val.replace(/:| |_/g, "").length === 6 ? true : false;
            })
            .test("endcurrenttime", "End Time must be greater than current time", (val, data) => {
                return startEndCurrentTimeValidation(data.parent, 2);
            })
            .test("startendtime", "End time must be greater than start time", (val, data) => {
                return startEndValidation(data.parent)
            })
            .test("startendtime", "Host is not open for business for the end time you selected", (val, data) => {
                return hoursOfOperationStartEndValidation(data.parent.end_datetime)
            })
            .test(
            "endtime_diff",
            `Invalid time range. Make sure start & end times are ${priceOption} ${priceType ? priceType.toLowerCase() : ''} apart`, (val, data) => {
                return intervalOfHours(data?.parent?.start_datetime, data?.parent?.end_datetime)
            })
    });

    const getHOP = (datetime) => {
        var day = moment(datetime).format('dddd');
        return beachGear?.hoursOfOperation?.find(v => v.day == day);
    }

    const startEndCurrentTimeValidation = (data, type) => {
        if (type == 1) return moment(data.start_datetime) > moment();
        else if (type == 2) return moment(data.end_datetime) > moment();
    }

    const hoursOfOperationStartEndValidation = (datetimes, type = 'hours') => {
        if (type == 'hours') {
            var datetime = moment(datetimes);

            var hop = getHOP(datetime);
            var hopopen = moment(datetime.format('YYYY-MM-DD') + ' ' + hop.open, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss');
            var hopclose = moment(datetime.format('YYYY-MM-DD') + ' ' + hop.close, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss');
            if (!hop.status) return true;

            if (hop && hop.status == '1' && moment(hopopen) <= datetime && moment(hopclose) >= datetime) return true;

            return false;
        } 
        
        return false;
    }

    const startEndValidation = (datas) => {
        if (datas.start_time == undefined || datas.end_time == undefined) return false;
        return AppHelper.validateStartEnd(datas.start_time, datas.end_time, datas.start_date, datas.end_date);
    }

    const formik = useFormik({
        initialValues: {
            price: '',
            quantity: '',
            start_datetime: '',
            end_datetime: '',
            start_date: '',
            end_date: '',
            start_time: '10:00 AM',
            end_time: '05:00 PM',
            start_time_checkbox: false,
            end_time_checkbox: true,
            is_delivery_pickup: 0,
            delivery_address: [],
            pickup_address: [],
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            var cartdata = {
                beachgear_host_id: beachGear.id,
                price_id: values.price,
                quantity: values.quantity,
                start_date_time: values.start_datetime,
                end_date_time: values.end_datetime,
                is_delivery_pickup: deliveryPickupDisable ? 0 : values.is_delivery_pickup,
                delivery_address: deliveryPickupDisable ? [] : (values.delivery_address != '' ? values.delivery_address : []),
                pickup_address: deliveryPickupDisable ? [] : (values.pickup_address != '' ? values.pickup_address : [])
            };

            if (searchresult != null && searchresult != undefined && sessionStorage.getItem('searchItems') != null) cartdata.search = JSON.parse(sessionStorage.getItem('searchItems')).data;

            if (cartItem?.cart_id) {
                CartService.update(cartdata, cartItem?.cart_id).then(resp => {
                    MakeToast("success", "Beach Gear updated in cart");
                    if (pageType == 'hostprofile') {
                        handleClose();
                        dispatch({ type: 'CART_UPDATE', payload: true });
                    } else {
                        navigate.push('/cart');
                    }
                })
            } else {
                CartService.add(cartdata).then(resp => {
                    MakeToast("success", "Beach Gear added to cart");
                    if (pageType == 'hostprofile') {
                        handleClose();
                        dispatch({ type: 'CART_UPDATE', payload: true });
                    } else {
                        navigate.push('/cart');
                    }
                })
            }

            return false;
        },
    });

    let values = formik.values;

    const [startHOP, setStartHOP] = React.useState({});
    const [endHOP, setEndHOP] = React.useState({});
    const [calculationLoader, setCalculationLoader] = React.useState(false);

    const updateTiming = () => {
        setCalculation({})
        setCalculationLoader(true)

        let values = formik.values;
        var starttime = values.start_time;
        var endtime = values.end_time;

        if (
            starttime != '' && starttime != undefined && starttime.replace(/:| |_/g, "").length === 6 &&
            endtime != '' && endtime != undefined && endtime.replace(/:| |_/g, "").length === 6
        ) {
            var startdatetime = AppHelper.dateTimeFormat(starttime, startDate);
            var enddatetime = AppHelper.dateTimeFormat(endtime, endDate);

            formik.setFieldValue('start_datetime', startdatetime)
            formik.setFieldValue('end_datetime', enddatetime)
            setStartHOP(getHOP(startdatetime));
            setEndHOP(getHOP(enddatetime));

            if (calculationInterval) clearTimeout(calculationInterval)
            calculationInterval = setTimeout(() => {
                formik.validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0) {
                        getCalculation();
                    }
                });
            }, 1000);
        }

        setCalculationLoader(false);
    }

    const [calculation, setCalculation] = React.useState({});
    const getCalculation = () => {
        setCalculationLoader(true)
        values.cartid = cartItem != undefined ? cartItem.cart_id : null;

        BookingService.calculation(values, beachGear.id).then(res => {
            var result = res.data.data;

            if (result.availablity_status == '1') {
                setCalculation(result);
                formik.setFieldValue('price', result.price_id)
            } else {
                if (open) {
                    if (result.availablity_date == '0' && result.availablity_qty == '0') MakeToast("error", "Out of Stock for selected date and quantity");
                    else if (result.availablity_date == '0' && result.availablity_qty == '1') MakeToast("error", "Out of Stock for selected date");
                    else if (result.availablity_date == '1' && result.availablity_qty == '0') MakeToast("error", "Out of Stock for selected quantity");
                    else if (!result.away_mode) MakeToast("error", "Host is not open for business for the date you selected")
                }
            }

            setCalculationLoader(false)
        }).catch((error) => {
        });
    }

    React.useEffect(() => {
        formik.setFieldValue('quantity', quantity);
        formik.setFieldValue('start_date', startDate);
        formik.setFieldValue('end_date', endDate);

        getPriceTypeOption();
        updateTiming();

        if(start_date=='' && end_date==''){
            (formik.values.start_date == formik.values.end_date) ? setDayOption(1) : setDayOption(0)
        }
    }, [
        open,
        formik.values.start_date,
        formik.values.end_date,
        formik.values.start_time,
        formik.values.end_time,
        formik.values.start_datetime,
        formik.values.end_datetime,
        formik.values.start_time_checkbox,
        formik.values.end_time_checkbox,
        formik.values.quantity,
        formik.values.price,
        startDate,
        endDate,
        deliveryPickupDisable,
        deliveryAddressData,
        pickupAddressData
    ]);

    React.useEffect(() => {
        if (cartItem != undefined && Object.keys(cartItem).length) {
            setStartDate(AppHelper.convertDateTime(cartItem?.start_datetime, 1))
            setEndDate(AppHelper.convertDateTime(cartItem?.end_datetime, 1))
            let starttime = AppHelper.convertDateTime(cartItem?.start_datetime, 2);
            let endtime = AppHelper.convertDateTime(cartItem?.end_datetime, 2);
            formik.setFieldValue('start_time', starttime);
            formik.setFieldValue('end_time', endtime);
            formik.setFieldValue('start_time_checkbox', starttime.includes('PM'));
            formik.setFieldValue('end_time_checkbox', endtime.includes('PM'));
            formik.setFieldValue('is_delivery_pickup', cartItem?.is_delivery_pickup);
            formik.setFieldValue('delivery_address', cartItem?.delivery_address);
            formik.setFieldValue('pickup_address', cartItem?.pickup_address);
        }else if(startTimeValue!=undefined && endTimeValue!=undefined && startTimeValue!='' && endTimeValue!=''){
            setStartDate(start_date)
            setEndDate(end_date)
            formik.setFieldValue('start_time', startTimeValue);
            formik.setFieldValue('end_time', endTimeValue);
            formik.setFieldValue('start_time_checkbox', startTimeValue.includes('PM'));
            formik.setFieldValue('end_time_checkbox', endTimeValue.includes('PM'));
        }
    }, [open]);

    React.useEffect(() => {
        formik.setFieldValue('delivery_address', deliveryAddressData)
    }, [deliveryAddressData]);

    React.useEffect(() => {
        formik.setFieldValue('pickup_address', pickupAddressData)
    }, [pickupAddressData]);

    React.useEffect(() => {
        formik.setFieldValue('price', selectedPriceData)
    }, [selectedPriceData]);

    const setRangePicker = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        
        if(start_date!='' && end_date!=''){
            dispatch({
                type: "SET_DATE",
                payload: {
                    start_date: start,
                    end_date: end,
                }
            });
            
            dispatch({
                type: "CALCULATION_UPDATE",
                payload: true
            });
        }
    }

    const handleFavourites = () => {
        FavoriteService.addBeachGear(beachGear.id).then(res => {
            MakeToast("success", "Beach gear has been saved to favorites");
            handleClose();
            navigate.push('/');
        }).catch((error) => {
        });
    }

    return (
        <>
            <RangePicker selector="#range-picker-service" startdate={startDate} enddate={endDate} setRangePicker={setRangePicker} mindate={new Date()} setMinSelection={false} />
            <ModalPopup
                contentStyle={{ width: '600px', width1: '550px', padding: '0', padding1: '0', padding2: '0' }}
                className="modal-popup search-order"
                open={open}
                onClose={handleClose}
                content={<>
                    <form onSubmit={formik.handleSubmit}>
                        <Box className="modalheader">
                            <Box id="modal-modal-title" className="modal-heading text-center" component="h2">
                                Order Form
                            </Box>
                            <Box className="modal-close-btn">
                                <Button size="small" disableRipple onClick={() => setOpen(false)}> <img src={images.closeIcon} alt="" /></Button>
                            </Box>
                        </Box>
                        <Divider></Divider>
                        <Box className="modalbody">
                            <Typography component="h6" className="modal-sub-title">Service</Typography>
                            <Box className="filter-form">
                                <FormControl sx={{
                                    width: '425px',
                                    border: '0 !important',
                                    '@media (max-width:769px)': {
                                        width: '370px',
                                    },
                                    '@media (max-width:583px)': {
                                        width: '100%',
                                    },
                                }} className="input-style">
                                    <TextField
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        placeholder={beachGear.title}
                                        className="outline-input textfield-rouded-md  input-text-style"
                                        name="name"
                                        sx={{
                                            '.MuiInputBase-input': {
                                                paddingLeft: '30px',
                                            },

                                            '.MuiInputBase-root': {
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#eee',
                                                },
                                                '&.Mui-focused': {
                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #eee',
                                                    }
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#eee',
                                                }
                                            },
                                        }}
                                    />
                                </FormControl>
                                <MenuList
                                    autoFocusItem={open}
                                    id="search-bear-menu"
                                    aria-labelledby="search-bear-button"
                                    // anchorOrigin={{
                                    //     vertical: 'bottom',
                                    //     horizontal: 'left',
                                    // }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    sx={{ py: 0 }}
                                >
                                    <MenuItem className="backgrond-remove"
                                        sx={{
                                            "&:hover": { backgroundColor: "transparent", },
                                            padding: '0'
                                        }}
                                    >
                                        <Box component="div" className="beachgear-value-input amount-added">
                                            <p>Quantity</p>
                                            <QuantityInput
                                                decrement={() => {
                                                    if (values.quantity >= 2) {
                                                        var quantity = values.quantity - 1;
                                                        formik.setFieldValue('quantity', quantity);
                                                        setQuantity(quantity);
                                                    }
                                                }}
                                                quantity={values.quantity}
                                                increment={() => {
                                                    if (values.quantity >= 1) {
                                                        var quantity = values.quantity + 1;
                                                        formik.setFieldValue('quantity', quantity);
                                                        setQuantity(quantity);
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </MenuItem>
                                </MenuList>
                            </Box>
                            <Box className='dotted-border-2'></Box>
                            {beachGear.deliveryPickupService && beachGear.categoryId == 1 ?
                                <>
                                    <Box className='dotted-border-2'></Box>
                                    <Typography component="h6" className="modal-sub-title">Would you like delivery or pickup services?</Typography>
                                    <Box className="radio-check-button">
                                        <FormControl>
                                            <RadioInput
                                                customArr={{ data: yesno, name: 'name', id: 'id' }}
                                                name="is_delivery_pickup"
                                                value={values.is_delivery_pickup}
                                                onChange={formik.handleChange}
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: '16px',
                                                    paddingRight: '30px',
                                                    MarginRight: '0px',
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    {values.is_delivery_pickup == 1 ?
                                        <>
                                            <Box className='dotted-border-2'></Box>
                                            <Typography component="h6" className="modal-sub-title">Delivery address</Typography>
                                            <Box className="search-order-popup">
                                                <FormControl className="input-style reduce-bottom-space"
                                                    sx={{
                                                        width: '100%',
                                                        border: '0 !important',
                                                        '@media (max-width:769px)': {
                                                            width: '310px',
                                                        },
                                                        '@media (max-width:583px)': {
                                                            width: '280px',
                                                        },
                                                        '@media (max-width:575px)': {
                                                            width: '100%',
                                                        }

                                                    }}>
                                                    <GoogleAutoCompleteInput
                                                        placeholder=""
                                                        name="delivery_address"
                                                        value={values.delivery_address?.address}
                                                        onChange={formik.handleChange}
                                                        setGoogleAutocompleteData={setDeliveryAddressData}
                                                        className="outline-input input-text-style textfield-rouded-md"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={images.searchIcon} alt="" />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{
                                                            '.MuiInputBase-root': {
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                },
                                                                '&.Mui-focused': {
                                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #eee',
                                                                    }
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                }
                                                            },
                                                        }}
                                                        disabled={deliveryPickupDisable}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='dotted-border-2'></Box>
                                            <Typography component="h6" className="modal-sub-title">Pickup address</Typography>
                                            <Box className="search-order-popup">
                                                <FormControl className="input-style reduce-bottom-space"
                                                    sx={{
                                                        width: '100%',
                                                        border: '0 !important',
                                                        '@media (max-width:769px)': {
                                                            width: '310px',
                                                        },
                                                        '@media (max-width:583px)': {
                                                            width: '280px',
                                                        },
                                                        '@media (max-width:575px)': {
                                                            width: '100%',
                                                        }

                                                    }}>
                                                    <GoogleAutoCompleteInput
                                                        setGoogleAutocompleteData={setPickupAddressData}
                                                        placeholder=""
                                                        name="pickup_address"
                                                        value={values.pickup_address?.address}
                                                        onChange={formik.handleChange}
                                                        className="outline-input input-text-style textfield-rouded-md"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={images.searchIcon} alt="" />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{
                                                            '.MuiInputBase-root': {
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                },
                                                                '&.Mui-focused': {
                                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                                        border: '1px solid #eee',
                                                                    }
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#eee',
                                                                }
                                                            },
                                                        }}
                                                        disabled={deliveryPickupDisable}
                                                    />
                                                </FormControl>
                                            </Box>
                                            {(calculation.pickup && calculation.pickup.status == 0) || (calculation.delivery && calculation.delivery.status == 0)
                                                ?
                                                <Box className="pickupdeliverymessage">
                                                    <Typography component="h6">It looks like your Host doesn’t travel this far for delivery & pickup. Continue without delivery & pickup from this Host ?</Typography>
                                                    <RadioInput
                                                        customArr={{ data: yesno, name: 'name', id: 'id' }}
                                                        value={deliveryPickupOption}
                                                        onChange={(e) => {
                                                            var data = e.target.value;
                                                            setDeliveryPickupOption(data);

                                                            if (data == '1') {
                                                                setDeliveryPickupDisable(true)
                                                            } else if (data == '0') {
                                                                handleFavourites()
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <></>
                            }
                            <Typography component="h6" className="modal-sub-title" style={{marginTop:'15px'}}>Rates : </Typography>
                            <Box class="section_pricelist">
                                <RadioInput
                                    customArr={{ data: priceData, name: 'name', id: 'id'  }}
                                    dayOption={dayOption}
                                    name="price"
                                    value={selectedPriceData}
                                    onChange={(e) => {
                                        setSelectedPriceData(e.target.value)
                                    }}
                                    sx={{
                                        fontSize: '16px',
                                        paddingRight: '30px',
                                        width: '100%'
                                    }}
                                />
                            </Box>
                            <Box className='dotted-border-2'></Box>
                            <Box className="border-radius-md search-result-box-2 order-date-box"
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },
                                    '@media (max-width:769px)': {
                                        width: '100%',
                                    },
                                }}>
                                <Box className="border-right-radius" sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },

                                }}>
                                    <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                        <img src={images.calendarIcon} alt="location" />
                                        Beginning date
                                    </Typography>
                                    <FormControl className="input-style small-radius border-0 border-right-radius"
                                    >
                                        <Input
                                            placeholder="08-12-2023"
                                            underline="none"
                                            readOnly
                                            disableUnderline={true}
                                            className="outline-input textfield-rouded-md input-text-style small-radius service-picker"
                                            value={startDate}
                                            sx={{
                                                borderRadius: '18px !important',
                                                '.MuiInputBase-input': {
                                                    paddingLeft: '30px',
                                                    fontSize: '18px',
                                                    fontWeight: '400',
                                                },
                                                '.MuiInputBase-root': {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    },
                                                    '&.Mui-focused': {
                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #eee',
                                                            border: '0',
                                                        }
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    }
                                                },
                                            }}
                                            onClick={() => { document.getElementById('range-picker-service').click() }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '12px',
                                    width: '50%',
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },
                                }}>
                                    <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                        <img src={images.calendarIcon} alt="location" />
                                        End date
                                    </Typography>
                                    <FormControl className="input-style small-radius border-0 border-left-radius"
                                    >
                                        <Input
                                            id="range-picker-service"
                                            readOnly
                                            placeholder="08-12-2023"
                                            underline="none"
                                            disableUnderline={true}
                                            value={endDate}
                                            name='end_date'
                                            onChange={(e) => {
                                                formik.setFieldValue('end_date', endDate);
                                            }}
                                            className="outline-input textfield-rouded-md input-text-style small-radius service-picker endDate"
                                            sx={{
                                                borderRadius: '18px !important',
                                                '.MuiInputBase-input': {
                                                    paddingLeft: '30px',
                                                    fontSize: '18px',
                                                    fontWeight: '400',
                                                },
                                                '.MuiInputBase-root': {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    },
                                                    '&.Mui-focused': {
                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #eee',
                                                            border: '0',
                                                        }
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    }
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <FormHelperText className="field-helper-text error" style={{ marginBottom: "15px" }}>{formik?.errors?.start_date}</FormHelperText>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormHelperText className="field-helper-text error" style={{ marginBottom: "15px" }}>{formik?.errors?.end_date}</FormHelperText>
                                </Grid>
                            </Grid>
                            <Typography component="h6" className="modal-sub-title">Choose your desired reservation start & end times</Typography>
                            <Typography component="h7" className="text-small-modal" style={{
                                fontSize: '15px !important',
                                marginLeft: '10px',
                            }}>Host hours of operation</Typography>
                            <Box className='time-field'>
                                <FormControl sx={{
                                    width: '50%',
                                    height: 'unset !important',
                                    border: '0 !important',
                                    '@media (max-width:769px)': {
                                        marginBottom: '15px',
                                        '@media (max-width:566px)': {
                                            marginBottom: '0px',
                                        }
                                    },
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },
                                }} className="input-style">
                                    <span style={{ color: '#808084', width: '100%', padding: "10px" }}>{startHOP?.day} - {startHOP?.status == 1 ? startHOP?.open + ' to ' + startHOP?.close : 'Closed'}</span>
                                    <TimeInput
                                        id={'starttimetext'}
                                        placeholder="Start Time"
                                        name="start_time"
                                        value={values.start_time}
                                        onChange={(e) => {
                                            var text = e.target.value;
                                            var split = text.split(' ');

                                            formik.setFieldValue('start_time', split[0] + ' ' + (split[1] == 'PM' || split[1] == 'pM' ? 'PM' : 'AM'));
                                            formik.setFieldValue('start_time_checkbox', (split[1] == 'PM' || split[1] == 'pM') ? true : false);
                                        }}
                                        onBlur={(e) => {
                                            formik.handleChange(e);
                                            document.getElementById('starttimeampmbtn').style.display = "none";
                                        }}
                                        onClick={(e) => {
                                            if (e.target.value == '__:__ AM') e.target.setSelectionRange(0, 0);
                                            document.getElementById('starttimeampmbtn').style.display = "";
                                        }}
                                        className="serviceorder_timeinput"
                                        idSwitch={"starttimeampmbtn"}
                                        checkedSwitch={values.start_time_checkbox}
                                        onChangeSwitch={(e) => {
                                            var text = values.start_time;
                                            var split = text.split(' ');

                                            formik.setFieldValue('start_time', split[0] + ' ' + (e.target.checked ? 'PM' : 'AM'));
                                            formik.setFieldValue('start_time_checkbox', e.target.checked ? true : false);
                                        }}
                                    />
                                    <FormHelperText className="field-helper-text error" style={{ marginBottom: "15px" }}>{formik?.errors?.start_time}</FormHelperText>
                                </FormControl>
                                <FormControl sx={{
                                    width: '50%',
                                    height: 'unset !important',
                                    border: '0 !important',
                                    '@media (max-width:769px)': {
                                        marginBottom: '15px',
                                    },
                                    '@media (max-width:566px)': {
                                        width: '100% !important',
                                    },

                                }} className="input-style">
                                    <span style={{ color: '#808084', width: '100%', padding: "10px" }}>{endHOP?.day} - {endHOP?.status == 1 ? endHOP?.open + ' to ' + endHOP?.close : 'Closed'}</span>
                                    <TimeInput
                                        id={'endtimetext'}
                                        placeholder="End Time"
                                        name="end_time"
                                        value={values.end_time}
                                        onChange={(e) => {
                                            var text = e.target.value;
                                            var split = text.split(' ');

                                            formik.setFieldValue('end_time', split[0] + ' ' + (split[1] == 'PM' || split[1] == 'pM' ? 'PM' : 'AM'));
                                            formik.setFieldValue('end_time_checkbox', (split[1] == 'PM' || split[1] == 'pM') ? true : false);
                                        }}
                                        onBlur={(e) => {
                                            formik.handleChange(e);
                                            document.getElementById('endtimeampmbtn').style.display = "none";
                                        }}
                                        onClick={(e) => {
                                            if (e.target.value == '__:__ AM') e.target.setSelectionRange(0, 0);
                                            document.getElementById('endtimeampmbtn').style.display = "";
                                        }}
                                        className="serviceorder_timeinput"
                                        idSwitch={"endtimeampmbtn"}
                                        checkedSwitch={values.end_time_checkbox}
                                        onChangeSwitch={(e) => {
                                            var text = values.end_time;
                                            var split = text.split(' ');

                                            formik.setFieldValue('end_time', split[0] + ' ' + (e.target.checked ? 'PM' : 'AM'));
                                            formik.setFieldValue('end_time_checkbox', e.target.checked ? true : false);
                                        }}
                                    />
                                    <FormHelperText className="field-helper-text error" style={{ marginBottom: "15px" }}>{formik?.errors?.end_time}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box className='dotted-border-2'></Box>
                            <Box className="cart-summary">
                                {
                                    calculationLoader
                                        ?
                                        <>
                                            <ListItemText className="summary-price"><Skeleton variant="rectangular" width={200} height={30} /><span><Skeleton variant="rectangular" width={150} height={30} /></span></ListItemText>
                                            <ListItemText className="summary-price"><Skeleton variant="rectangular" width={200} height={30} /><span><Skeleton variant="rectangular" width={150} height={30} /></span></ListItemText>
                                            <ListItemText className="summary-price"><Skeleton variant="rectangular" width={200} height={30} /><span><Skeleton variant="rectangular" width={150} height={30} /></span></ListItemText>
                                        </>
                                        :
                                        <></>
                                }
                                {Object.keys(calculation).length
                                    ?
                                    <List component="div" disablePadding>
                                        {calculation.hasOwnProperty("item_charge")
                                            ?
                                            <ListItemText className="summary-price">Subtotal <span>{AppHelper.currency()}{calculation.item_charge}</span></ListItemText>
                                            :
                                            <></>
                                        }
                                        {calculation.hasOwnProperty("security_deposit")
                                            ?
                                            <ListItemText className="summary-price">Security Deposit <span>{AppHelper.currency()}{calculation.security_deposit}</span></ListItemText>
                                            :
                                            <></>
                                        }
                                        {calculation.hasOwnProperty("service_charge")
                                            ?
                                            <ListItemText className="summary-price">Beachify Service Fee : <span>{AppHelper.currency()}{calculation.service_charge}</span></ListItemText>
                                            :
                                            <></>
                                        }
                                        {calculation.hasOwnProperty("delivery") && calculation.delivery.status == 1
                                            ?
                                            <ListItemText className="summary-price">Delivery Charges <span>{AppHelper.currency()}{calculation.delivery.charge}</span></ListItemText>
                                            :
                                            <></>
                                        }
                                        {calculation.hasOwnProperty("pickup") && calculation.pickup.status == 1
                                            ?
                                            <ListItemText className="summary-price">Pickup Charges <span>{AppHelper.currency()}{calculation.pickup.charge}</span></ListItemText>
                                            :
                                            <></>
                                        }
                                        {calculation.hasOwnProperty("total")
                                            ?
                                            <ListItemText className="summary-price summary-price-total">Total <span>{AppHelper.currency()}{calculation.total}</span></ListItemText>
                                            :
                                            <></>
                                        }
                                    </List>
                                    :
                                    <></>
                                }
                            </Box>
                        </Box>
                        {
                            calculationLoader
                                ?
                                <Box className="filter-menu-footer">
                                    <Skeleton className="save-btn" variant="rectangular" width={300} height={40} />
                                </Box>
                                :
                                <></>
                        }
                        {calculation.hasOwnProperty("total")
                            ?
                            <Box className="filter-menu-footer">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="border-radius-md btn-primary save-btn"
                                    disabled={(((calculation.pickup && calculation.pickup.status == 0) || (calculation.delivery && calculation.delivery.status == 0)) && deliveryPickupOption == '') || formik.isSubmitting}
                                >
                                    {formik.isSubmitting ? <CircularProgress /> : "Add to Cart"}
                                </Button>
                            </Box>
                            :
                            <></>
                        }
                    </form>
                </>}
            />
        </>
    )
}

export default ServiceOrder;