import { Box, Button, CircularProgress, FormControl, IconButton, ListItemIcon, ListItemSecondaryAction, Radio, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import React from 'react';
import { useSelector } from 'react-redux';
import MakeToast from '../../Helpers/MakeToast';

const PaymentForm = (props) => {
    const { user } = useSelector((state) => state.userReducers.data);
    const [loading, setLoading] = React.useState(false)

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!stripe || !elements) {
            setLoading(false);
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: user.name,
            },
        });

        if (result.error) {
            setLoading(false);
            MakeToast("error", result.error?.message);
        } else {
            MakeToast("success", "Your card Info will be stored securely");
            props.onEmitValue(result.paymentMethod);
            setLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box className="modalbody" sx={{ paddingBottom: '30px !important', '@media (max-width:575.98px)':{ paddingBottom: '20px !important' } }}>
                    <Grid container rowSpacing={{ xs: 2, sm: 3, md: 3 }} columnSpacing={{ xs: 2, sm: 3, md: 3 }}>
                        <Grid xs={12}>
                            <Typography variant="h3">Payment Data:</Typography> 
                       </Grid>
                        <Grid xs={12}>
                            <FormControl sx={{ width: '100%', border: '0 !important'}} className="input-style">
                                <TextField
                                    InputProps={{ inputComponent: CardNumberElement }}
                                    placeholder={'Card Number'}
                                    className="outline-input textfield-rouded-md  input-text-style"
                                    name="name"
                                    sx={{
                                        // '.MuiInputBase-input': {
                                        //     paddingLeft: '30px',
                                        // },

                                        '.MuiInputBase-root': {
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#eee',
                                            },
                                            '&.Mui-focused': {
                                                ' .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #eee',
                                                }
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#eee',
                                            }
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <FormControl sx={{
                                width: '100%', border: '0 !important',
                                    '@media (max-width:769px)': {
                                        marginBottom: '15px',
                                        '@media (max-width:566px)': {
                                            marginBottom: '0px',
                                        }
                                    },
                                }} className="input-style">
                                <TextField
                                    InputProps={{ inputComponent: CardExpiryElement }}
                                    placeholder={'Card Number'}
                                    className="outline-input textfield-rouded-md  input-text-style"
                                    name="name"
                                    sx={{
                                        // '.MuiInputBase-input': {
                                        //     paddingLeft: '30px',
                                        // },

                                        '.MuiInputBase-root': {
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#eee',
                                            },
                                            '&.Mui-focused': {
                                                ' .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #eee',
                                                }
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#eee',
                                            }
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <FormControl sx={{
                                width: '100%', border: '0 !important',
                                '@media (max-width:769px)': {
                                    marginBottom: '15px',
                                },
                                '@media (max-width:566px)': {
                                    width: '100% !important',
                                },

                            }} className="input-style">
                                <TextField
                                    InputProps={{ inputComponent: CardCvcElement }}
                                    placeholder={'Card Number'}
                                    className="outline-input textfield-rouded-md  input-text-style"
                                    name="name"
                                    sx={{
                                        // '.MuiInputBase-input': {
                                        //     paddingLeft: '30px',
                                        // },

                                        '.MuiInputBase-root': {
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#eee',
                                            },
                                            '&.Mui-focused': {
                                                ' .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #eee',
                                                }
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#eee',
                                            }
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                {stripe ?
                        <Box className="filter-menu-footer" >

                            <Button
                                type="submit"
                                variant="contained"
                                className="border-radius-md btn-primary save-btn"
                                disabled={!stripe || loading}
                            >
                                {loading ?
                                    <CircularProgress />
                                    : "Confirm"
                                }
                            </Button>
                        </Box>
                        :
                        <></>
                    }
            </form>
        </>
    )
};

export default PaymentForm;