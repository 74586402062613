import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Box,
    Container,
    FormControl,
    TextField,
    InputAdornment,
    Typography,
    Button,
    Input,
    useMediaQuery,
} from '@mui/material';
import { useDispatch } from "react-redux";

import images from "../../assets/js/images";
import AppHelper from "../../Helpers/AppHelper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import BeachGear from "../../Components/Home/BeachGear";
import ActivitiesPopover from "../../Components/Common/ActivitiesPopover";
import RangePicker from "../../Components/Inputs/RangePicker";
import GoogleAutoCompleteInput from "../../Components/Inputs/GoogleAutoCompleteInput";
import NoResultsFound from "../../Components/Common/NoResultsFound";
import FilterModal from "../../Components/Modal/FilterModal";

import { HomePageService } from "../../Services/HomePageService";

var searchInterval;
const SearchResult = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const isLargeScreen = useMediaQuery('(min-width: 768px)')
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)')

    const [popularBGDatas, setPopularBGDatas] = React.useState({});
    const [searchStorage, setSearchStorage] = React.useState({});
    const [openFilter, setOpenFilter] = React.useState(false);
    const [searchName, setSearchName] = React.useState('');
    const [address, setAddress] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [anchorActivitiesEl, setAnchorActivitiesEl] = React.useState(null);

    const handleToggleActivities = (e) => {
        setAnchorActivitiesEl(e.currentTarget);
    };
    const handleCloseActivities = (event) => {
        setAnchorActivitiesEl(null);
    };

    React.useEffect(() => {
        sessionFilter();
    }, [open]);

    const locations = useLocation();
    const searchParams = new URLSearchParams(locations.search);
    const searchparam = searchParams.get("search");

    React.useEffect(() => {
        if (searchparam != null) {
            sessionFilter();
            window.history.replaceState(null, null, window.location.pathname);
        }
    }, [searchparam]);

    const sessionFilter = () => {
        var searchData = sessionStorage.getItem('searchItems') != null ? JSON.parse(sessionStorage.getItem('searchItems')).data : {};
        setSearchStorage(searchData);
        setSearchName(searchData.search)

        if (searchData.location && searchData.location.address) {
            setAddress(searchData.location.address);
        }

        if (searchData.start_date != '' && searchData.end_date != '') {
            setStartDate(searchData.start_date);
            setEndDate(searchData.end_date);
        }

        getFilterData(searchData);
    }

    const getFilterData = (searchData) => {
        if (searchInterval) clearTimeout(searchInterval);
        searchInterval = setTimeout(() => {
            HomePageService.filterBeachgears(searchData).then(res => {
                setPopularBGDatas(res.data.data);
            })
        }, 1000)
    }

    const setRangePicker = (start, end) => {
        setStartDate(start);
        setEndDate(end);

        dispatch({
            type: 'SET_DATE',
            payload: {
                start_date: start,
                end_date: end,
            },
        });

        navigate.push('/search-result?search=' + AppHelper.searchRandomNumber());
    }

    const [googleAutocompleteData, setGoogleAutocompleteData] = React.useState({})
    React.useEffect(() => {
        if (googleAutocompleteData.address) {
            setAddress(googleAutocompleteData.address)

            dispatch({
                type: "SET_LOCATION",
                payload: googleAutocompleteData
            });

            navigate.push('/search-result?search=' + AppHelper.searchRandomNumber());
        }
    }, [googleAutocompleteData]);

    return (
        <>
            <section className='search-result section-padding'>
                <RangePicker selector="#filter-range-picker" startdate={startDate} enddate={endDate} setRangePicker={setRangePicker} />
                <Container>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <BreadCrumb data={[["Search Results"]]} sx={{ paddingLeft: '15px' }} />
                        <Grid xs={12} className='search-result-heading'>
                            <Box className='top-heading'>
                                <div className="main-heading-block" sx={{ marginBottom: '0 !important' }}>
                                    <h2 className="main-heading" sx={{
                                        marginBottom: '0 !important',
                                        marginTop: '0 !important',
                                    }}>Search Results
                                    </h2>
                                    <Box className='sub-text tertiary-text'>{popularBGDatas.length} result(s)</Box>
                                </div>
                            </Box>
                            {isLargeScreen && (
                                <FormControl
                                    sx={{
                                        width: '380px',
                                        '@media (min-width: 768px) and (max-width: 991.98px)': {
                                            '.input-text-style > div': {
                                                width: '100% !important',
                                            },
                                        },
                                        '@media (min-width:1024px)': {
                                            display: 'none',
                                        },
                                    }}
                                    className="input-style reduce-bottom-space search-listing-space"
                                >
                                    <TextField
                                        placeholder="Search"
                                        className="outline-input input-text-style"
                                        value={searchName}
                                        onChange={(e) => {
                                            setSearchName(e.target.value);
                                            dispatch({
                                                type: 'SET_SEARCH',
                                                payload: e.target.value,
                                            });

                                            var homedatas = searchStorage;
                                            homedatas.search = e.target.value;
                                            getFilterData(homedatas);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={images.searchIcon} alt="" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#eee',
                                                },
                                                '&.Mui-focused': {
                                                    ' .MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #eee',
                                                    },
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#eee',
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>)}
                        </Grid>
                        <Grid xs={12}>
                            <Box className="over-all-search">
                                <Box className="border-radius-md border-color search-result-box-2"
                                    sx={{
                                        '@media (min-width:1024px)': {
                                            display: 'none',
                                        },
                                        width: '160px',
                                        '@media (max-width:769px)': {
                                            width: '235px',
                                        },
                                        '@media (max-width:566px)': {
                                            width: '100%',
                                        },
                                        '@media (min-width:567px) and (max-width:575px)': {
                                            width: '100%',
                                        }
                                    }}>
                                    <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                        <img src={images.locationIcon} alt="location" />
                                        Location
                                    </Typography>
                                    <FormControl className="input-style small-radius border-0"
                                    >
                                        <GoogleAutoCompleteInput
                                            placeholder="Location"
                                            value={address}
                                            onChange={(e) => { setAddress(e.target.value) }}
                                            setGoogleAutocompleteData={setGoogleAutocompleteData}
                                            className="searchLocationBox outline-input textfield-rouded-md input-text-style small-radius"
                                            sx={{
                                                borderRadius: '18px !important',
                                                '.MuiInputBase-input': {
                                                    paddingLeft: '30px',
                                                    fontSize: '18px',
                                                    fontWeight: '400',
                                                },
                                                '.MuiInputBase-root': {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    },
                                                    '&.Mui-focused': {
                                                        ' .MuiOutlinedInput-notchedOutline': {
                                                            border: '0',
                                                        }
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#eee',
                                                        border: '0',
                                                    }
                                                },
                                                '&:before': {
                                                    borderBottom: 'none'
                                                }
                                            }}
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            variant="standard"
                                        />
                                    </FormControl>
                                </Box>
                                <Box className="border-radius-md border-color search-result-box-2"
                                    sx={{
                                        width: '330px',
                                        display: 'flex',
                                        '@media (min-width:1024px)': {
                                            display: 'none',
                                        },
                                        '@media (max-width:566px)': {
                                            width: '100% !important',
                                        },
                                        '@media (max-width:769px)': {
                                            width: '400px',
                                        },
                                        '@media (min-width:567px) and (max-width:576px)': {
                                            width: '100%',
                                        }
                                    }}>
                                    <Box className="border-right-radius" sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                            <img src={images.calendarIcon} alt="location" />
                                            Beginning date
                                        </Typography>
                                        <FormControl className="input-style small-radius border-0 border-right-radius"
                                        >
                                            <TextField
                                                placeholder="Start Date"
                                                className="start-range-picker outline-input textfield-rouded-md input-text-style small-radius"
                                                value={startDate}
                                                onClick={() => document.getElementById('filter-range-picker').focus()}
                                                sx={{
                                                    borderRadius: '18px !important',
                                                    '.MuiInputBase-input': {
                                                        paddingLeft: '30px',
                                                        fontSize: '18px',
                                                        fontWeight: '400',
                                                    },
                                                    '.MuiInputBase-root': {
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                            border: '0',
                                                        },
                                                        '&.Mui-focused': {
                                                            ' .MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #eee',
                                                                border: '0',
                                                            }
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                            border: '0',
                                                        }
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: '12px',
                                    }}>
                                        <Typography variant="h6" component="label" className="sub-text tertiary-text label-bottom-space">
                                            <img src={images.calendarIcon} alt="location" />
                                            End date
                                        </Typography>
                                        <FormControl className="input-style small-radius border-0 border-left-radius"
                                        >
                                            <TextField placeholder="End Date"
                                                className="start-range-picker outline-input textfield-rouded-md input-text-style small-radius"
                                                value={endDate}
                                                id="filter-range-picker"
                                                readOnly
                                                sx={{
                                                    borderRadius: '18px !important',
                                                    '.MuiInputBase-input': {
                                                        paddingLeft: '30px',
                                                        fontSize: '18px',
                                                        fontWeight: '400',
                                                    },
                                                    '.MuiInputBase-root': {
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                            border: '0',
                                                        },
                                                        '&.Mui-focused': {
                                                            ' .MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #eee',
                                                                border: '0',
                                                            }
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#eee',
                                                            border: '0',
                                                        }
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                {isSmallScreen && (<Box sx={{ width: '100%' }}>
                                    <FormControl className="input-style reduce-bottom-space search-listing-space">
                                        <Input
                                            id="input-with-icon-adornment search-bear-button"
                                            className="border-radius-md border-color search-result-box-2"
                                            placeholder="Beach Gear & Activities"
                                            readOnly={true}
                                            underline="none"
                                            disableUnderline={true}
                                            aria-haspopup="true"
                                            value={''}
                                            onClick={handleToggleActivities}
                                            sx={{height: '60px'}}
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    className="input-image"
                                                    underline="none"
                                                    sx={{ pl: 1.5, pr: 1 }}
                                                >
                                                    <img src={images.searchIcon} alt="search" />
                                                </InputAdornment>
                                            }
                                        />
                                        <ActivitiesPopover
                                            anchorActivitiesEl={anchorActivitiesEl}
                                            handleCloseActivities={handleCloseActivities}
                                        />
                                    </FormControl>
                                </Box>)}
                                <Box className="search-btn"
                                    sx={{
                                        '@media (min-width:1024px)': {
                                            display: 'none',
                                        }
                                    }}>
                                    <Button
                                        onClick={() => {
                                            setOpenFilter(true);
                                        }}
                                        variant="contained"
                                        className="search-result-filter rounded-button"
                                    >
                                        <img src={images.filterIcon} alt="filter" />
                                    </Button>
                                    <div className='rental-search-form modal-filter-btn search-result-btn'>
                                        <Button
                                            type="submit"
                                            fullHeight
                                            disableElevation
                                            variant="contained"
                                            href="#"
                                            onClick={() => {
                                                setOpenFilter(true);
                                            }}
                                            className="rounded-button filter-button"
                                        >
                                            <img src={images.filterIcon} alt="filter" />
                                            <span>Filter</span>
                                        </Button>
                                    </div>
                                    {openFilter && <>
                                        <FilterModal isOpen={openFilter} setLoginOpen={setOpenFilter} sliderMaxValue={1000} />
                                    </>}
                                </Box>
                            </Box>
                        </Grid>
                        {popularBGDatas?.length > 0 &&
                            <Grid xs={12} className='beach-detail-bottom-space'>
                                <div className="grid-5">
                                    {popularBGDatas.map(gData => <BeachGear beachgear={gData} pagetype="searchresult" />)}
                                </div>
                            </Grid>
                        }
                    </Grid>
                    {popularBGDatas?.length === 0 && <NoResultsFound />}
                </Container>
            </section>
        </>
    )
}

export default SearchResult;
